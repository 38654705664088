<template>
    <div class="flex flex--100 update-allergen-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 update-allergen-form">
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <h2>{{ $t(requiredLocalization.name) }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.generic.name')"  v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100 update-allergen">
                    <simple-button v-if="saved.localizations !== undefined" :text="$t('generic.update')" @click="updateAllergen" :is-disabled="!dataChanged" :is-loading="isWaitingServerResponse"/>
                    <simple-button :text="$t('generic.delete')" @click="deleteAllergen" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { user } from "@/user";
import { RestaurantProcessor } from '@/utilities';
import {notificationCenter} from "@/components/utilities/NotificationCenter";
/*
const ProductAddErrorType = {
    MISSING_INDEX: {
        id: 1,
        text: "Index is required.",
    },
    NEGATIVE_INDEX: {
        id: 2,
        text: "Index cannot be negative.",
    },
};
*/
export default {
    name: "UpdateAllergenModalBox",
    props: {
        allergen: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        restaurantPresentation: {
            type: Object,
        },
        closeOnAllergenUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "allergen-update", "allergen-delete" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            localizations: {},
            saved: {},
            isWaitingServerResponse: false,
        };
    },
    methods: {
        async load () {
            this.localizations = this.generateLocalizations();
            this.saved.localizations = JSON.parse(JSON.stringify(this.localizations));
        },

        validateUpdateAllergenForm () {/*
             const nameTextbox = this.$refs.nameTextbox;
             nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);
             return nameTextbox.errors.length === 0;*/return true;
        },

        clearAllergenForm () {
            this.localizations = {};
        },

        async updateAllergen () {
            if (!this.validateUpdateAllergenForm() || this.isWaitingServerResponse) {
                return;
            }
            this.isWaitingServerResponse = true;
            try {
                const allergen = await user.updateAllergen({
                    id: this.allergen.id,
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });
                if (allergen) {
                    this.$emit("allergen-update", {allergen, oldId: this.allergen.id,});
                    if (this.closeOnAllergenUpdate) {
                        this.window.hide();
                    }
                    this.clearAllergenForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.allergenUpdatedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },

        async deleteAllergen () {
            if (!this.validateUpdateAllergenForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const allergenId = await user.removeAllergen({ id: this.allergen.id, });
                if (allergenId) {
                    this.$emit("allergen-delete", allergenId);
                    if (this.closeOnAllergenUpdate) {
                        this.window.hide();
                    }
                    this.clearAllergenForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.allergenDeletedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },
        generateLocalizations () {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    if (this.allergen && this.allergen.localizations) {
                        const existingLocalization = this.allergen.localizations.find(l => l.languageIso === iso);
                        localizations[iso] = {
                            name: existingLocalization ? existingLocalization.name : '',
                        };
                    } else {
                        localizations[iso] = {
                            name: '',
                        };
                    }
                });
            }
            return localizations;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        },
        dataChanged () {
            for (const languageIso of Object.keys(this.localizations)) {
                if (this.localizations[languageIso].name !== this.saved.localizations[languageIso].name) {
                    return true;
                }
            }
            return false;
        }
    },

    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>
<style lang="scss" scoped>
.update-allergen-modal-box {}
.update-allergen-form {
    padding: 50px;
}
.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
.update-allergen-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
    .simple-button {
        margin: 0 50px 0 0;
    }
}
</style>
