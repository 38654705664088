<template>
    <div class="flex flex--100 cook-order-point-view">
        <simple-language-selector :available-languages="displayLanguages"/>
        <div class="flex main">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center no-orders-in-preparation" v-if="!hasOrdersToPrepare">
                <span class="no-orders-in-preparation__text">{{ $t("order.noOrdersToDeliver") }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center main__list" v-else>
                <div class="flex flex--x-align-center main__box" v-for="order in sortedOrdersInPreparation" :key="order.id">
                    <div class="flex flex--x-align-center flex--y-align-center order-delivery-time" v-if="order.requestedDeliveryTimestamp > 0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="order-delivery-time__icon">
                            <circle cx="12" cy="12" r="10" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6l4 4"/>
                        </svg>
                        <span class="order-delivery-time__text">
                             {{ $t("generic.reservation") }}: {{ (new Date(order.requestedDeliveryTimestamp)).toLocaleString("it") }}
                        </span>
                    </div>
                    <div class="flex order">
                        <div class="flex flex--100 flex--y-align-center">
                            <div class="flex flex--70">
                                <h2 class="order__daily-id-text">{{ order.sceneId ? `${getOrderSceneLocalizedName(order.sceneId)} #${order.dailyId}` : `${$t("generic.order")} #${order.dailyId}` }}{{  order.tables ? `, ${$t('order.tableAbb')} ${order.tables[0].name}` : '' }}</h2>
                            </div>
                            <div class="flex flex--30 flex--x-align-end">
                                <template>
                                    <h3 class="order__paid" v-if="restaurantId === 6">
                                        <template v-if="order.paymentMethod !== null">{{ $t("generic.paid").toLocaleUpperCase() }}</template>
                                    </h3>
                                    <template v-if="order.requestedPersons > 0">
                                        <span class="order__seats" >{{ order.requestedPersons }}</span>
                                        <svg class="order__seats-icon" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                                            </path>
                                        </svg>
                                    </template>
                                </template>
                                <h3 class="order__time-text">
                                    <template v-if="order.deliveryLocation === OrderDeliveryLocation.TAKE_AWAY">{{ $t("generic.takeAway").toUpperCase() }} &bull; </template>
                                    <template v-if="showThirdParty && order.origin === OrderOrigin.THIRD_PARTY">
                                    {{ 
                                        Number.isFinite(order.paymentMethod) ? $t(`order.paymentMethodMap.${order.paymentMethod}`) : 'N/D'
                                    }} &bull;
                                    </template>
                                    <template v-if="order.deliveryLocation === OrderDeliveryLocation.ADDRESS">DELIVERY &bull;</template>
                                    {{ restaurantId === 1 ? getOrderPaymentTimeString(order): getOrderTimeString(order) }}
                                </h3>
                            </div>
                        </div>
                        <div class="flex flex--100 order-message" v-if="order.message">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="order-message__icon">
                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                            </svg>
                            <span class="order-message__text">
                                {{ order.message }}
                            </span>
                        </div>
                        <div class="flex flex--100 flex--direction-column">
                            <!-- <base-product-list> -->
                             <template v-if="showCategories">
                                <div class="flex flex--100 flex--y-align-center product" v-for="orderProductByCategory in getOrderBaseProductsGroupByCategory(order)" :key="orderProductByCategory.categoryId">
                                    <span class="flex flex--100 category__name">{{ getCategoryLocalizedName(orderProductByCategory.categoryId) }} {{ getCategoryTime(order, orderProductByCategory.categoryId) }}</span>
                                        <template v-for="orderProduct in orderProductByCategory.products" :key="orderProduct.id">
                                        <div class="flex flex--100 flex--y-align-center product product__box"
                                            :class="getOrderProductModifiers(orderProduct)"
                                        >
                                            <div class="flex product__box" @click="toggleOrderProductDeliveredVolume(order, orderProduct)">
                                                <simple-checkbox
                                                    class="completed-product-checkbox"
                                                    :model-value="orderProductIsDelivered(orderProduct)"
                                                    :disabled="true"
                                                />
                                                <span class="product__name-text" :class="getOrderProductNameModifiers(orderProduct)">
                                                    x{{ getOrderProductVolumeToDeliver(orderProduct) }} {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                </span>
                                            </div>
                                            <div class="flex flex--100 product__special-request-box" v-if="orderProduct.message">
                                                <p class="product__special-request-text" :class="getOrderProductSpecialRequestTextModifiers(orderProduct)">
                                                    {{ orderProduct.message }}
                                                </p>
                                            </div>
                                            <template v-for="(item, i) in orderProduct.requestedVolume">
                                                <div class="flex flex--100 product-variation-box" :key="i" v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                    <p class="product-variation-box__text" :class="getOrderProductVariationTextModifiers(orderProduct)">
                                                        x1 {{ getOrderProductVariationsTextByVolumeIndex(orderProduct, i) }}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div class="flex flex--100 flex--y-align-center product"
                                    v-for="orderProduct in getOrderBaseProducts(order)"
                                    :key="orderProduct.id"
                                    :class="getOrderProductModifiers(orderProduct)"
                                >
                                    <div class="flex product__box" @click="toggleOrderProductDeliveredVolume(order, orderProduct)">
                                        <simple-checkbox
                                            class="completed-product-checkbox"
                                            :model-value="orderProductIsDelivered(orderProduct)"
                                            :disabled="true"
                                        />
                                        <span class="product__name-text" :class="getOrderProductNameModifiers(orderProduct)">
                                            x{{ getOrderProductVolumeToDeliver(orderProduct) }} {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                        </span>
                                    </div>
                                    <div class="flex flex--100 product__special-request-box" v-if="orderProduct.message">
                                        <p class="product__special-request-text" :class="getOrderProductSpecialRequestTextModifiers(orderProduct)">
                                            {{ orderProduct.message }}
                                        </p>
                                    </div>
                                    <template v-for="(item, i) in orderProduct.requestedVolume">
                                        <div class="flex flex--100 product-variation-box" :key="i" v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                            <p class="product-variation-box__text" :class="getOrderProductVariationTextModifiers(orderProduct)">
                                                x1 {{ getOrderProductVariationsTextByVolumeIndex(orderProduct, i) }}
                                            </p>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <!-- </base-product-list> -->
                            <!-- <fixed-menu-list> -->
                            <template v-for="fixedMenu in getOrderFixedMenus(order)">
                                <div class="flex flex--direction-column product" v-for="orderFixedMenu in fixedMenu.requestedMenus" :key="orderFixedMenu.id">
                                    <span class="product__name-text product__name-text--fixed-menu" :class="getOrderFixedMenuModifiers(orderFixedMenu)">
                                        {{ restaurantProcessor.getProductLocalizedName(fixedMenu.id) }}
                                    </span>
                                    <div class="flex flex--direction-column fixed-menu-product-list">
                                        <div
                                            class="flex flex--y-align-center fixed-menu-product"
                                            v-for="orderFixedMenuProduct in orderFixedMenu.products"
                                            :key="orderFixedMenuProduct.id"
                                            :class="getOrderProductModifiers(orderFixedMenuProduct)"
                                            @click="toggleOrderFixedMenuProductDeliveredVolume(order, fixedMenu, orderFixedMenu, orderFixedMenuProduct)"
                                        >
                                            <simple-checkbox
                                                class="completed-product-checkbox"
                                                :model-value="orderProductIsDelivered(orderFixedMenuProduct)"
                                                :disabled="true"
                                            />
                                            <span class="fixed-menu-product__name" :class="getOrderFixedMenuProductModifiers(orderFixedMenuProduct)">
                                                x{{ orderFixedMenuProduct.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(orderFixedMenuProduct.id) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- </fixed-menu-list> -->
                            <!-- <custom-product-list> -->
                            <template v-for="customProduct in getOrderCustomProducts(order)">
                                <div
                                    class="flex flex--direction-column product"
                                    v-for="orderCustomProduct in customProduct.requestedMenus"
                                    :key="orderCustomProduct.id"
                                    @click="toggleOrderCustomProductProductDeliveredVolume(order, customProduct, orderCustomProduct)"
                                >
                                    <span class="product__name-text product__name-text--fixed-menu" :class="getOrderCustomProductModifiers(orderCustomProduct)">
                                        <simple-checkbox class="completed-product-checkbox" :model-value="orderCustomProductIsDelivered(orderCustomProduct)"
                                            :disabled="true" />
                                        {{ restaurantProcessor.getProductLocalizedName(customProduct.id) }}
                                    </span>
                                    <div class="flex flex--direction-column fixed-menu-product-list">
                                        <div
                                            class="flex flex--y-align-center fixed-menu-product"
                                            v-for="orderCustomProductProduct in orderCustomProduct.products"
                                            :key="orderCustomProductProduct.id"
                                            :class="getOrderProductModifiers(orderCustomProductProduct)"
                                        >
                                            <span class="fixed-menu-product__name" :class="getOrderCustomProductProductModifiers(orderCustomProductProduct)">
                                                x{{ orderCustomProductProduct.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(orderCustomProductProduct.id) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- </custom-product-list> -->
                        </div>
                        <div class="flex flex--100">
                            <done-button class="complete-order-button" @click="completeOrderDelivery(order.id)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex header">
            <!-- <div class="flex flex--100 flex--x-align-center">
                <primary-logo/>
            </div> -->
            <div class="flex flex--100 flex--x-align-center">
                <simple-button :text="$t('kitchen.completedOrders')" class="completed-orders-button" :is-loading="isWaitingServerResponseForCompletedOrders" @click="showCompletedOrders"/>
            </div>
            <div class="flex flex--100 flex--x-align-center scene-box">
                <simple-selectbox
                    class="scene-select-box"
                    :options="sceneSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')"
                    @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox"
                    v-model:model-value="selectedScene"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center show-categories">
                <span class="flex flex--50 flex--x-align-center flex--y-align-center show-categories__text">{{ $t("generic.showCategories") }}</span>
                <simple-checkbox appearance="slider" class="flex flex--50 flex--x-align-center flex--y-align-center  show-categories__btn" v-model:model-value="showCategories"/>
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center show-categories">
                <span class="flex flex--50 flex--x-align-center flex--y-align-center show-categories__text">{{ $t("generic.showThirdParty") }}</span>
                <simple-checkbox appearance="slider" class="flex flex--50 flex--x-align-center flex--y-align-center  show-categories__btn" v-model:model-value="showThirdParty"/>
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center show-categories">
                <simple-button :text="$t('generic.fixStuck')" :is-loading="isWaitingServerResponseForFixStuck" @click="fixStuckOrder"/>
            </div>
            <!--
            <div class="flex flex--100 flex--x-align-center">
                <simple-button :text="$t('generic.products')" class="update-orders-button"/>
            </div>
            -->
            <div class="flex flex--100 flex--x-align-center" v-if="isDesktop">
                <simple-button class="exit-application-button" :text="$t('generic.exit')" @click="exitApplication"/>
            </div>
            <span class="flex flex--100 flex--x-align-center time">{{ normalizedTimeString }}</span>
        </div>
        <modal-box class="cook-modal-box order-list-modal-box" ref="completedOrderListModalBox">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("kitchen.completedOrders") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <restaurant-order
                    v-for="order in sortedCompletedOrders"
                    :key="order.id"
                    :restaurant-presentation="restaurantPresentation"
                    :order="order"
                    :compact-view="false"
                    :show-price="false"
                />
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import DoneButton from "@/components/DoneButton";
import SimpleLanguageSelector from "@/components/inputs/SimpleLanguageSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import RestaurantOrder from "@/components/RestaurantOrder"
// import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { isDesktop, i18n, } from "@/main";
import { OrderDeliveryLocation, OrderStatus, OrderPaymentMethod, OrderOrigin, }  from "@/orders/RestaurantOrder";
import { server, WS_API_URI, } from "@/server";
import {RestaurantProcessor, WSKeepAlive,} from "@/utilities";
import { cloneDeep } from "lodash";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
// import { notificationCenter } from "@/components/utilities/NotificationCenter";

const preloadedData = {};
const orderInPreparationAudio = new Audio(require("@/audio/order-in-preparation.mp3"));
let primaryWs;
let showCategories = false;
let showThirdParty = false;
let selectedScene;
function createPrimaryWs () {
    try {
        primaryWs = new WSKeepAlive(WS_API_URI);
    }
    catch {
        // Silence is golden.
    }
}

export default {
    async beforeRouteEnter (to, from, next) {
        const restaurantId = to.params.restaurantId;
        const [ restaurantPresentation, ordersInPreparation, ordersInDelivery, ] = await Promise.all([
            server.getRestaurantPresentation(restaurantId),
            server.getRestaurantOrders(restaurantId, OrderStatus.IN_PREPARATION),
            server.getRestaurantOrders(restaurantId, OrderStatus.IN_DELIVERY),
        ]);

        if (!restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }

        preloadedData.restaurantPresentation = restaurantPresentation;
        preloadedData.ordersInPreparation = [ ...ordersInPreparation, ...ordersInDelivery, ];
        showCategories = String(restaurantId) === "6";
        showThirdParty = String(restaurantId) === "3";
        selectedScene = !to.query.sceneId || to.query.sceneId === "undefined" || to.query.sceneId === "all" ? undefined : to.query.sceneId;
        next();
    },

    name: "WaiterDeliveryPointView",
    components: {
        SimpleLanguageSelector,
        // PrimaryLogo,
        DoneButton,
        SimpleButton,
        SimpleCheckbox,
        RestaurantOrder,
        ModalBox,
        SimpleSelectbox,
    },
    data () {
        return {
            OrderDeliveryLocation,
            OrderPaymentMethod,
            OrderOrigin,
            ordersInPreparation: preloadedData.ordersInPreparation,
            completedOrders: [],
            isWaitingServerResponseForCompletedOrders: false,
            ordersListenerIntervalId: undefined,
            time: new Date(),
            selectedScene,
            showCategories,
            showThirdParty,
            isWaitingServerResponseForFixStuck: false,
        };
    },
    methods: {
        // <modifiers>
        getOrderProductModifiers (product) {
            return {
                "product--in-preparation": !this.orderProductIsCompleted(product),
            };
        },

        getOrderProductNameModifiers (product) {
            return {
                "product__name-text--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderProductSpecialRequestTextModifiers (product) {
            return {
                "product__special-request-text--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderProductVariationTextModifiers (product) {
            return {
                "product-variation-box__text--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderFixedMenuModifiers (menu) {
            return {
                "product__name-text--delivered": this.orderFixedMenuIsDelivered(menu),
            };
        },

        getOrderFixedMenuProductModifiers (product) {
            return {
                "fixed-menu-product__name--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderCustomProductModifiers (menu) {
            return {
                "product__name-text--delivered": this.orderCustomProductIsDelivered(menu),
            };
        },

        getOrderCustomProductProductModifiers (product) {
            return {
                "fixed-menu-product__name--delivered": this.orderProductIsDelivered(product),
            };
        },
        // </modifiers>

        exitApplication () {
            try {
                const { ipcRenderer, } = window.require("electron");

                primaryWs.close();
                ipcRenderer.send("exit");
            }
            catch {
                // Silence is golden.
            }
        },

        orderProductIsCompleted (product) {
            return product.requestedVolume === product.completedVolume;
        },

        getOrderProductVolumeToDeliver (orderProduct) {
            const requestedVolume = orderProduct.requestedVolume;
            const deliveredVolume = orderProduct.deliveredVolume;

            if (deliveredVolume === 0 || deliveredVolume === requestedVolume) {
                return requestedVolume;
            }

            return requestedVolume - deliveredVolume;
        },

        orderProductIsDelivered (product) {
            return product.requestedVolume === product.deliveredVolume;
        },

        orderFixedMenuIsCompleted (orderFixedMenu) {
            return orderFixedMenu.products.every((product) => this.orderProductIsCompleted(product));
        },

        orderFixedMenuIsDelivered (orderFixedMenu) {
            return orderFixedMenu.products.every((product) => this.orderProductIsDelivered(product));
        },

        orderCustomProductIsCompleted (orderCustomProduct) {
            return orderCustomProduct.products.every((product) => this.orderProductIsCompleted(product));
        },

        orderCustomProductIsDelivered (orderCustomProduct) {
            return orderCustomProduct.products.every((product) => this.orderProductIsDelivered(product));
        },

        getOrderProductVariationsByVolumeIndex (orderProduct, volumeIndex) {
            return orderProduct.variations.filter(
                (variation) => variation.volumeIndex === volumeIndex
            ).map(
                (variation) => this.restaurantProcessor.getVariationById(variation.id)
            );
        },

        orderProductVolumeIndexHasVariations (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).length > 0;
        },

        getOrderProductVariationsTextByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (text, variation) => text += ", " + this.restaurantProcessor.getVariationLocalizedName(variation.id), ""
            );
        },

        async updateOrdersInPreparation () {
            this.ordersInPreparation = await server.getRestaurantOrders(this.restaurantId, OrderStatus.IN_PREPARATION);
        },

        async updateOrdersInPreparationBySceneId (sceneId) {
            this.ordersInPreparation = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_PREPARATION, sceneId);
        },

        async updateOrdersInPreparationAndInDelivery () {
            const ordersInDelivery = await server.getRestaurantOrders(this.restaurantId, OrderStatus.IN_DELIVERY);
            const ordersInPreparation = await server.getRestaurantOrders(this.restaurantId, OrderStatus.IN_PREPARATION);
            this.ordersInPreparation = [ ...ordersInPreparation, ...ordersInDelivery];
        },

        async updateOrdersInPreparationAndInDeliveryBySceneId (sceneId) {
            const ordersInDelivery = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_DELIVERY, sceneId);
            const ordersInPreparation = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_PREPARATION, sceneId);
            this.ordersInPreparation = [ ...ordersInPreparation, ...ordersInDelivery];
        },

        async updateCompletedOrders () {
            if (this.isWaitingServerResponseForCompletedOrders) {
                return;
            }

            this.isWaitingServerResponseForCompletedOrders = true;
            this.completedOrders = await server.getRestaurantOrders(this.restaurantId, OrderStatus.CLOSED);
            this.isWaitingServerResponseForCompletedOrders = false;
        },

        async showCompletedOrders () {
            await this.updateCompletedOrders();
            this.$refs.completedOrderListModalBox.show();
        },

        playNewOrderNotificationSound () {
            orderInPreparationAudio.play();
        },

        async toggleOrderProductDeliveredVolume (order, orderProduct) {
            const deliveredVolume = this.orderProductIsDelivered(orderProduct) ? 0 : orderProduct.requestedVolume;

            try {
                await server.updateOrderProductDeliveredVolume({
                    orderId: order.id,
                    productId: orderProduct.id,
                    deliveredVolume: deliveredVolume,
                });

                orderProduct.deliveredVolume = deliveredVolume;
            }
            catch (error) {
                console.log(error);
            }
        },

        async toggleOrderFixedMenuProductDeliveredVolume (order, fixedMenu, orderFixedMenu, orderFixedMenuProduct) {
            const menuIsDelivered = this.orderFixedMenuIsDelivered(orderFixedMenu);
            const deliveredVolume = this.orderProductIsDelivered(orderFixedMenuProduct) ? 0 : orderFixedMenuProduct.requestedVolume;

            try {
                await server.updateOrderProductDeliveredVolume({
                    orderId: order.id,
                    productId: orderFixedMenuProduct.id,
                    deliveredVolume,
                    orderFixedMenuId: orderFixedMenu.id,
                });

                orderFixedMenuProduct.deliveredVolume = deliveredVolume;

                if (!menuIsDelivered && this.orderFixedMenuIsDelivered(orderFixedMenu)) {
                    ++fixedMenu.deliveredVolume;
                }
                else if (menuIsDelivered && !this.orderFixedMenuIsDelivered(orderFixedMenu)) {
                    --fixedMenu.deliveredVolume;
                }
            }
            catch (error) {
                console.log(error);
            }
        },

        async toggleOrderCustomProductProductDeliveredVolume(order, customProduct, orderCustomProduct) {
            console.log(orderCustomProduct);
            const menuIsDelivered = this.orderCustomProductIsDelivered(orderCustomProduct);
            const deliveredVolume = this.orderProductIsDelivered(orderCustomProduct.products[0]) ? 0 : 1;

            try {
                await server.updateOrderProductDeliveredVolume({
                    orderId: order.id,
                    productId: customProduct.id,
                    deliveredVolume,
                    orderCustomProductId: orderCustomProduct.id,
                });

                orderCustomProduct.deliveredVolume = deliveredVolume;

                if (!menuIsDelivered && this.orderCustomProductIsDelivered(orderCustomProduct)) {
                    ++customProduct.deliveredVolume;
                }
                else if (menuIsDelivered && !this.orderCustomProductIsDelivered(orderCustomProduct)) {
                    --customProduct.deliveredVolume;
                }
            }
            catch (error) {
                console.log(error);
            }
        },

        getOrderBaseProducts (order) {
            return order.products.filter((product) =>
                !this.restaurantProcessor.productIsFixedMenu(Number.parseInt(product.id))
            && !this.restaurantProcessor.productIsCustomProduct(Number.parseInt(product.id)));
        },

        getOrderFixedMenus (order) {
            return order.products.filter((product) => this.restaurantProcessor.productIsFixedMenu(Number.parseInt(product.id)));
        },

        getOrderCustomProducts (order) {
            return order.products.filter((product) => this.restaurantProcessor.productIsCustomProduct(Number.parseInt(product.id)));
        },

        async completeOrderDelivery (id) {
            const order = this.ordersInPreparation.find((order) => order.id === id);

            if (order.status !== OrderStatus.IN_DELIVERY) {
                return;
            }

            const hasDeliveredAllProducts = order.products.every((product) => this.orderProductIsDelivered(product));

            if (!hasDeliveredAllProducts) {
                return;
            }

            const nextStatus = order.paymentMethod === null ? OrderStatus.TO_PAY : OrderStatus.CLOSED;

            try {
                await server.updateOrder({
                    id,
                    status: nextStatus,
                });
            }
            catch {
                // Silence is golden.
            }
        },

        getOrderTimeString (order) {
            return new Date(order.creationTimestamp).toLocaleTimeString(this.$i18n.locale).split(":", 2).join(":");
        },

        getOrderPaymentTimeString (order) {
            return new Date(order.paymentTimestamp).toLocaleTimeString(this.$i18n.locale).split(":", 2).join(":");
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },

        getOrderBaseProductsGroupByCategory (order) {
            const products = this.getOrderBaseProducts(order);
            const groupByCategory = products.reduce((arr, p) => {
                const categoryId = this.restaurantProcessor.products.find((pp) => pp.id === p.id).categories[0].id;
                const found = arr.find((cp) => cp.categoryId === categoryId);
                if (found) {
                    found.products.push({
                        ...p,
                    });
                }
                else {
                    arr.push({
                        categoryId,
                        products: [{ ...p, }],
                    });
                }
                return arr;
            }, []).sort((cp1, cp2) => this.restaurantProcessor.listedCategories.findIndex((c) => c.id === cp1.categoryId) - this.restaurantProcessor.listedCategories.findIndex((c) => c.id === cp2.categoryId));
            return groupByCategory;
        },

        getCategoryLocalizedName (id) {
            return this.restaurantProcessor.getCategoryLocalizedName(id);
        },

        getCategoryTime (order, categoryId) {
            return order.categoryTimes && order.categoryTimes[String(categoryId)] !== undefined ? this.$t(`categoryTimes.${order.categoryTimes[String(categoryId)]}`) : "";
        },

        getOrderSceneLocalizedName (sceneId) {
            return this.restaurantProcessor.getSceneLocalizedName(Number.parseInt(sceneId));
        },

        async fixStuckOrder () {
            if (this.isWaitingServerResponseForFixStuck) {
                return;
            }
            this.isWaitingServerResponseForFixStuck = true;
            const orders = this.ordersInPreparation.filter((order) => order.status === OrderStatus.IN_DELIVERY && !order.products.every((product) => product.requestedVolume === product.completedVolume));
            for (const order of orders) {
                const products = order.products.filter((product) => product.requestedVolume !== product.completedVolume);
                for (const orderProduct of products) {
                    await server.updateOrderProduct({
                        orderId: order.id,
                        productId: orderProduct.id,
                        completedVolume: orderProduct.requestedVolume,
                    });
                }
            }
            this.isWaitingServerResponseForFixStuck = false;
        },
    },
    computed: {
        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },

        displayLanguages () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },

        variations () {
            return this.restaurantProcessor.variations;
        },

        scenes () {
            return this.restaurantProcessor.scenes ?? [];
        },
        // </restaurant>

        isDesktop () {
            return isDesktop;
        },

        sortedOrdersInPreparation () {
            if (this.restaurantId === 1) {
                return [ ...this.ordersInPreparation, ].sort((a, b) => a.paymentTimestamp - b.paymentTimestamp);
            }
            return [ ...this.ordersInPreparation, ].sort((a, b) => a.creationTimestamp - b.creationTimestamp);
        },

        sortedCompletedOrders () {
            if (this.restaurantId === 1) {
                return [ ...this.completedOrders, ].sort((a, b) => b.paymentTimestamp - a.paymentTimestamp);
            }
            return [ ...this.completedOrders, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },

        hasOrdersToPrepare () {
            return this.ordersInPreparation.length > 0;
        },

        normalizedTimeString () {
            return `${this.time.toLocaleDateString(this.$i18n.locale)} ${this.time.toLocaleTimeString(this.$i18n.locale).split(":", 2).join(":")}`;
        },

        skipDeliveryState () {
            if (this.selected) {
                return this.selected.skipDeliveryState;
            }
            return ![ 1, ].includes(this.restaurantId);
        },

        paymentAfterService () {
            if (this.selected) {
                return this.selected.paymentAfterService;
            }
            return this.restaurantProcessor.restaurantConfiguration.paymentAfterService;
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [ ...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                }, ];
            }
            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        },
    },
    watch: {
        selectedScene (newValue) {
            if (newValue) {
                this.updateOrdersInPreparationAndInDeliveryBySceneId(newValue);
            }
        },
    },
    async created () {
        let token = "";
        try {
            if (isDesktop) {
                const { ipcRenderer, } = window.require("electron");
                const userData = JSON.parse(await ipcRenderer.invoke("host:getUserData"));
                if (userData) {
                    token = userData.token;
                }
            }
        } catch (e) {
            console.log(e);
        }
        
        this.ordersListenerIntervalId = setInterval(() => {
            if (!primaryWs || (primaryWs && (primaryWs.readyState === WebSocket.CLOSED || primaryWs.readyState === WebSocket.CLOSING))) {
                if (selectedScene) {
                    this.updateOrdersInPreparationBySceneId(selectedScene);
                }
                else {
                    this.updateOrdersInPreparation();
                }
            }
        }, 1000 * 20);

        setInterval(() => {
            this.time = new Date();
        }, 60000);

        try {
            if (!primaryWs) {
                createPrimaryWs();
            }

            primaryWs.addEventListener("open", () => {
                primaryWs.send(JSON.stringify({
                    restaurantId: this.restaurantId,
                    sceneId: selectedScene,
                    token,
                    service: {
                        name: "POD"
                    }
                }));
            });

            primaryWs.addEventListener("message", (event) => {
                let message;

                try {
                    message = JSON.parse(event.data);
                }
                catch {
                    return;
                }

                if (message.type === "RestaurantAuthentication" && message.result === "OK") {
                    console.log("connected to server");
                    this.updateOrdersInPreparationAndInDelivery();
                    if (selectedScene) {
                        this.updateOrdersInPreparationAndInDeliveryBySceneId(selectedScene);
                    }
                    else {
                        this.updateOrdersInPreparationAndInDelivery();
                    }
                }

                if (message.type === "OrderInPreparationNotification" || message.type === "OrderInDeliveryNotification" || message.type === "OrderProductUpdateNotification" || message.type === "OrderProductDeliveryUpdateNotification") {
                    const order = message.result.order;
                    const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                    if (!firstProduct) {
                        return;
                    }

                    if (firstProduct.restaurantId === this.restaurantId && (this.selectedScene === "all" || String(order.sceneId) === String(this.selectedScene))) {
                        const index = this.ordersInPreparation.findIndex((entity) => entity.id === order.id);

                        if (index !== -1) {
                            const copy = cloneDeep(this.ordersInPreparation);

                            copy[index] = order;
                            this.ordersInPreparation = copy;
                        }
                        else {
                            this.ordersInPreparation.push(order);
                        }

                        // this.playNewOrderNotificationSound();
                    }
                }
                else if (message.type === "OrderToPayNotification" || message.type === "OrderClosedNotification" || message.type === "OrderCancelledNotification") {
                    const order = message.result.order;
                    const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                    if (!firstProduct) {
                        return;
                    }

                    if (firstProduct.restaurantId === this.restaurantId && (this.selectedScene === "all" || String(order.sceneId) === String(this.selectedScene))) {
                        this.ordersInPreparation = this.ordersInPreparation.filter((orderInPreparation) => orderInPreparation.id !== order.id);
                    }
                }
            });
            primaryWs.keepAlive();
        }
        catch (error) {
            console.log(error);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.cook-order-point-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

.header {
    position: relative;

    width: 16%;
    height: 100%;

    background-color: rgb(44, 44, 44);

    z-index: 10;
}

.main {
    overflow-x: hidden;
    overflow-y: scroll;

    width: 84%;
    height: 100%;

    //background-color: rgb(240, 240, 240);
    background-color: rgb(220, 220, 220); // Darker upon request

    &__list {
        margin: 50px 0;
    }
    &__box {
        width: 44%;
    }
}

.order-message {
    margin: 0 0 20px 0;

    background-color: rgb(241,151,42);
    border-radius: 0 12px 12px 12px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

    &__text {
        margin: 20px 25px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        color: rgb(11, 11, 11);
    }

    &__icon {
        width: 22px;
        position: absolute;
        margin: 0;
        fill: transparent;
        transform: translate(-30%, -30%)
    }
}

.order-delivery-time {
    width: 76%;

    margin: 50px 0 0 0;
    padding: 20px 25px;

    background-color: rgb(46, 116, 230);
    background-image: linear-gradient(45deg, rgb(56, 126, 240), rgb(46, 116, 230));
    border-radius: 12px 12px 0 0;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

    z-index: 5;

    & + .order {
        margin-top: 0;
    }

    &__text {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: rgb(250, 250, 250);
    }

    &__icon {
        width: 22px;

        margin-right: 8px;

        fill: transparent;
    }
}

.order {
    position: relative;

    width: 90%;
    max-width: 910px;
    margin: 50px 0 35px 0;
    padding: 25px 25px 60px 25px;

    //background-color: rgb(255, 255, 255);
    background-color: $primary-brand-color;
    background-image: linear-gradient(45deg, rgb(231, 181, 55), $primary-brand-color);
    border-radius: 12px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

    &__daily-id-text {
        margin-bottom: 28px;

        font-size: 30px;
        font-weight: 800;
        //color: rgb(55, 55, 55);
        color: rgb(11, 11, 11);
    }

    &__time-text {
        margin-left: 5px;
        margin-bottom: 28px;

        //font-size: 18px;
        font-size: 24px; // Bigger upon request
        font-weight: 800;
        //color: rgb(60, 60, 60);
        color: rgb(11, 11, 11);
    }

    &__table-number {   
        font-size: 20px;
        font-weight: 800;
        color: rgb(100, 100, 100);
    }

    &__seats {
        margin-left: 5px;
        margin-bottom: 28px;
        font-size: 24px;
        font-weight: 800;
        color: #0b0b0b;
    }

    &__seats-icon {
        margin-top: 4px;
        width: 24px;
    }

    &__paid {   
        font-size: 24px;
        font-weight: 800;
    }
}

.product {
    &--in-preparation {
        pointer-events: none;
    }
    &--in-preparation &__name-text,
    &--in-preparation .simple-checkbox {
        opacity: 0.28;
    }

    &__name-text {
        margin-left: 12px;

        position: relative;

        //font-size: 18px;
        font-size: 24px; // Bigger upon request
        font-weight: 500;
        //color: rgb(33, 33, 33);
        color: rgb(11, 11, 11);
    }
    &__name-text--fixed-menu {
        margin-left: 0;
    }
    &__name-text--delivered {
        color: rgb(53, 53, 53);
    }
    &__name-text--delivered::before {
        content: "";

        position: absolute;
        left: 50%;
        top: 50%;

        width: calc(100% + 10px);
        height: 2px;

        //background-color: $primary-brand-color;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

        transform: translate(-50%, 50%);
    }

    &__box {
        cursor: pointer;
    }

    &__special-request-box {
        position: relative;

        margin: 23px 0;
        padding: 12px 20px;

        background-color: rgb(240, 104, 19);
        background-image: linear-gradient(45deg, rgb(240, 104, 19), rgb(253, 117, 32));
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.004), 0 0 16px 0 rgba(0, 0, 0, 0.006);
    }
    &__special-request-box::before {
        content: "";

        position: absolute;
        left: 50px;
        top: 0;

        width: 0;
        height: 0;

        margin-left: -16px;
        margin-top: -16px;

        border: 16px solid transparent;
        border-bottom-color: rgb(240, 104, 19);
        border-top: 0;
    }

    &__special-request-text {
        position: relative;

        font-size: 20px;
        font-weight: 500;
        color: rgb(11, 11, 11);
    }
    &__special-request-text--completed {
        color: rgb(53, 53, 53);
    }
    &__special-request-text--completed::before {
        content: "";

        position: absolute;
        left: 50%;
        top: 50%;

        width: calc(100% + 10px);
        height: 2px;

        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

        transform: translate(-50%, 50%);
    }

    & + & {
        margin-top: 12px;
    }
}

.special-request {
    &__text {}
}

.product-variation-box {
    position: relative;

    margin-top: 23px;
    padding: 12px 20px;

    background-color: rgb(240, 104, 19);
    background-image: linear-gradient(45deg, rgb(240, 104, 19), rgb(253, 117, 32));
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.004), 0 0 16px 0 rgba(0, 0, 0, 0.006);

    &__text {
        position: relative;

        font-size: 20px;
        font-weight: 500;
        color: rgb(11, 11, 11);
    }
    &__text--completed {
        color: rgb(53, 53, 53);
    }
    &__text--completed::before {
        content: "";

        position: absolute;
        left: 50%;
        top: 50%;

        width: calc(100% + 10px);
        height: 2px;

        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

        transform: translate(-50%, 50%);
    }
}
.product-variation-box::before {
    content: "";

    position: absolute;
    left: 50px;
    top: 0;

    width: 0;
    height: 0;

    margin-left: -16px;
    margin-top: -16px;

    border: 16px solid transparent;
    border-bottom-color: rgb(240, 104, 19);
    border-top: 0;
}

.completed-product-checkbox {
    width: 28px;
    height: 28px;

    :deep(.simple-checkbox__checkbox-input) {
        width: 28px;
        height: 28px;

        border-radius: 6px;
    }

    :deep(.simple-checkbox__checked-icon) {
        width: 22px;
        height: 22px;

        fill: rgb(255, 255, 255);
    }

    :deep(.simple-checkbox__checkbox-input:checked) {
        background-color: transparent;
        border-color: transparent;
    }
}

.completed-orders-button {
    margin: 54px 0 0 0;
}

.exit-application-button {

}

.complete-order-button {
    position: absolute;
    left: 50%;
    bottom: 0;

    background-color: rgb(140, 140, 140);

    transform: translate(-50%, 50%);
}

.cook-modal-box {
    :deep(.close-button) {
        right: 22px;
        top: calc(15vh - 22px);

        transform: translateY(-100%);

        z-index: 999;
    }

    :deep(.modal-box__slot) {
        overflow: hidden;

        position: relative;

        width: 100%;
        height: 85vh;
        margin: 15vh 0 0 0;
        padding: 0;

        border-radius: 0;
    }
}

.order-list-modal-box {
    :deep(.restaurant-order) {
        width: 40%;
        margin: 25px;

        &__expanded {
            height: auto;
        }

        .order-status,
        .restaurant-name,
        .id,
        .origin,
        .payment-method,
        .payment-date {
            display: none;
        }
    }
}

.order-list-title {
    overflow: hidden;

    height: 15%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__text {
        margin: 50px 0;

        font-size: 32px;
        font-weight: 600;
    }
}

.order-list {
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);
}

.primary-logo {
    width: 132px;
    height: 132px;
    margin: 48px 0 24px 0;

    fill: rgb(255, 255, 255);
}

.no-orders-in-preparation {
    overflow: hidden;

    height: 100%;

    &__text {
        font-size: 24px;
        font-weight: 600;
        color: rgb(150, 150, 150);
    }
}

.fixed-menu-product-list {
    margin: 0 0 0 20px;
}
.fixed-menu-product {
    cursor: pointer;

    margin-top: 12px;

    &__name {
        position: relative;

        margin-left: 12px;

        //font-size: 18px;
        font-size: 24px; // Bigger upon request
        font-weight: 500;
        //color: rgb(33, 33, 33);
        color: rgb(11, 11, 11);

        &--delivered {
            color: rgb(53, 53, 53);
        }
        &--delivered::before {
            content: "";

            position: absolute;
            left: 50%;
            top: 50%;

            width: calc(100% + 10px);
            height: 2px;

            //background-color: $primary-brand-color;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

            transform: translate(-50%, 50%);
        }
    }
}

.time {
    position: absolute;
    left: 50%;
    bottom: 28px;

    font-size: 18px;
    font-weight: 500;
    color: rgb(255, 255, 255);

    transform: translateX(-50%);
}

:deep(.language-selector) {
    position: absolute;
    left: 0;
    top: 0;

    &__selected {
        background-color: transparent;
    }

    &__dropdown {
        left: 0;
        min-width: 120px;
    }
}

.scene-select-box {
    min-width: 174px;
    :deep(.choices__item) {
        color: rgb(254,254,254);
        font-weight: 600;
        font-size: 15px;
    }
    :deep(.choices__inner) {
        border: none;
        background-color: rgb(77,77,77);
    }
    :deep(.choices__list) {
        border: none;
        border-radius: 0;
    }
    :deep(.choices__item--choice) {
        border: none;
        background-color: rgb(77,77,77);
    }
    :deep(.choices__item--choice) {
        font-size: 1rem;
        padding-right: 0;
    }
}

.scene-box {
    margin: 22px 0;
    padding: 0;
}

.category {
    &__name {
        position: relative;
        margin-bottom: 20px;

        //font-size: 18px;
        font-size: 24px; // Bigger upon request
        font-weight: 500;
        //color: rgb(33, 33, 33);
        color: rgb(11, 11, 11);
    }
}

.show-categories {
    margin: 22px 0;
    padding: 0;
    
    &__btn {
        font-size: 24px;
    }

    &__text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 600;
        color: rgb(254,254,254);
    }
}
</style>
