<template>
    <div class="flex flex--100 add-variation-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-variation-form">
                <div class="flex flex--100">
                    <simple-textbox :placeholder="$t('management.generic.price')"  v-model:model-value="price" ref="priceTextbox"/>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <h2>{{ $t(requiredLocalization.name) }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.generic.name')"  v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100">
                    <simple-button text="Add" @click="addVariation" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { RestaurantProcessor } from "@/utilities";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

export default {
    name: "AddVariationModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        restaurantPresentation: {
            type: Object,
        },
        closeOnVariationAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "variation-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            localizations: {},
            price: "",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateVariationCategoryForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearVariationForm () {
            this.localizations = {};
            this.price = "";
        },

        async addVariation () {
            if (!this.validateVariationCategoryForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const variation = await user.addVariation({
                    price: this.price,
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (variation) {
                    this.$emit("variation-add", variation);

                    if (this.closeOnVariationAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearVariationForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.variationAddedSuccess`), });
                }
                else{
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
        generateLocalizations() {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    localizations[iso] = {
                        name: '',
                    };
                });
            }
            return localizations;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        }
    },
    watch: {
        restaurantLocalizations: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.localizations = this.generateLocalizations();
                }
            }
        }
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>

.add-variation-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.add-variation-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}
</style>
