<template>
    <div class="flex flex--100 restaurant-product-analytics">
        <div class="flex flex--100 box">
            <div class="flex product-information" :class="productInformationModifier()">
                <span class="flex flex--100 header-text">{{localizedName}}</span>
                <span class="flex flex--60 header-text">{{$t(`management.generic.unitPrice`)}}:</span>
                <span class="flex flex--40 flex--x-align-end header-text" v-if="!product.isCustomProduct">€ {{ product.price }}</span>
                <span class="flex flex--40 flex--x-align-end header-text" v-else>-</span>
                <span class="flex flex--60 header-text">{{$t(`management.generic.total`)}}:</span>
                <span class="flex flex--40 flex--x-align-end header-text">{{ dailyCompletedVolume }}</span>
                <span class="flex flex--60 header-text">{{$t(`management.generic.discount`)}}:</span>
                <span class="flex flex--40 flex--x-align-end header-text">€ {{ dailyDiscounted }}</span>
                <span class="flex flex--60 header-text">{{$t(`management.generic.revenue`)}}:</span>
                <span class="flex flex--40 flex--x-align-end header-text">€ {{ dailyRevenue }}</span>
            </div>
            <div class="flex flex--40 flex--x-align-end product-image-container" v-if="product.previewImage">
                <img class="product-image" :src="product.previewImage.uri" alt="">
            </div>
            <div class="flex flex--100 daily-order-box">
                <span class="flex flex--50 daily-order-header-text">{{$t(`management.generic.origin`).toLocaleUpperCase()}}</span>
                <span class="flex flex--50 flex--x-align-end daily-order-header-text">{{$t(`management.generic.amount`).toLocaleUpperCase()}}</span>
                <div class="flex flex--100 daily-order-origin-list"
                     v-for="origin of Object.keys(origins)"
                     :key="origin">
                    <span class="flex flex--50 daily-order-origin-item">{{ getOriginText(origin) }}</span>
                    <span class="flex flex--50 flex--x-align-end daily-order-origin-item-value">{{product.origins[origin]}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RestaurantProductAnalytics",
    props: {
        product: {
            type: Object,
        },
        dailyCompletedVolume: {
            type: Number,
        },
        dailyRevenue: {
            type: Number,
        },
        dailyDiscounted: {
            type: Number,
        },
        origins: {
            type: Array,
        },
    },
    computed: {
        localizedName () {
            let locale = this.getLocalization(this.$i18n.locale);
            if (locale) {
                return locale.name;
            }
            return this.getLocalization("it")?.name ?? "";
        },
        price () {
            return this.product.price.toFixed(2);
        },

        validDiscount () {
            return !this.product.discountExpirationTimestamp || this.product.discountExpirationTimestamp > Date.now();
        },

        fixedDiscount () {
            if (!this.product.discountStrategies || !this.validDiscount) {
                return false;
            }
            const fixedDiscount = this.product.discountStrategies.find((s) => String(s).startsWith("-"));
            return fixedDiscount ? Number.parseFloat(fixedDiscount.substring(1)) : false;
        },

        percentageDiscount () {
            if (!this.product.discountStrategies || !this.validDiscount) {
                return false;
            }
            const percentageDiscount = this.product.discountStrategies.find((s) => String(s).startsWith("%"));
            return percentageDiscount ? Number.parseFloat(percentageDiscount.substring(1)) : false;
        },

        getProductFinalPrice () {
            let result = this.normalizePriceToDisplay(this.product.price);
            if (this.percentageDiscount) {
                result = this.normalizePriceToDisplay(Number.parseFloat(this.product.price) - (Number.parseFloat(this.product.price) * Number.parseFloat(this.percentageDiscount) / 100));
            }
            if (this.fixedDiscount) {
                result = this.normalizePriceToDisplay(Number.parseFloat(this.product.price) - Number.parseFloat(this.fixedDiscount));
            }
            return result;
        }
    },
    methods: {
        productInformationModifier () {
            return this.product.previewImage ? "flex--60" : "flex--100";
        },
        getLocalization (languageIso) {
            return this.product.localizations?.find((localization) => localization.languageIso === languageIso);
        },

        getOriginText (origin) {
            return this.$tm("management.generic.orderOrigin")[origin];
        },
    }
}
</script>

<style lang="scss" scoped>
.box {
    border-radius: 6px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    background-color: rgb(252, 252, 252);

    .product-information {
        padding: 20px 20px 0 20px;
    }

    .header-text {
        font-size: 14px;
        padding-bottom: 5px;
        font-weight: 500;
    }

    .product-image-container {
        padding: 20px 20px 0 20px;
        .product-image {
            width: 100%;
            border-radius: 6px;
        }
    }

    .daily-order-box {
        padding: 20px;
    }

    .daily-order-title {
        font-size: 14px;
        font-weight: 300;
        margin: 10px 0;
    }

    .daily-order-header-text {
        font-weight: 500;
    }

    .daily-order-origin-list {
        margin-top: 10px;
    }

    .daily-order-origin-item {
        font-weight: 400;
    }

    .daily-order-origin-item-value {
        font-weight: 400;
    }
}
</style>
