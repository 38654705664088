<template>
    <div class="update-product-modal-box">
        <modal-box ref="window" @hide="clearProductForm" :close-on-background-click="false">
            <form class="update-product-form">
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.price') }}</span>
                    <simple-textbox :placeholder="$t('management.generic.price')" v-model:model-value="price" ref="priceTextbox"/>
                </div>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.vat') }}</span>
                    <simple-textbox :placeholder="$t('management.generic.vat')" v-model:model-value="vat" ref="vatTextBox"/>
                </div>
                <!-- <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.previewImages') }}</span>
                    <simple-file-upload @upload="onPreviewImageUpload" :files-limit="1"/>
                </div> -->
                <hr class="separator"/>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.categories') }}</span>
                    <category-selector ref="categorySelector" :categories="categories" v-model:selected="selectedCategories" :max="100"/>
                </div>
                <!-- <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.allergens') }}</span>
                    <allergen-selector ref="allergenSelector" :allergens="allergens" v-model:selected="selectedAllergens" :max="100"/>
                </div> -->
                <!-- <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.variations') }}</span>
                    <variation-selector ref="variationSelector" :variations="variations" v-model:selected="selectedVariations" :max="100"/>
                </div> -->
                <hr class="separator"/>
                <div v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso" class="localization-section">
                    <h2 class="lang-title">{{ $t(requiredLocalization.name) }}</h2>
                    <simple-textbox :placeholder="$t('management.generic.name')" v-model:model-value="localizations[requiredLocalization.iso].name" ref="nameTextbox"/>
                    <simple-textbox :placeholder="$t('management.generic.description')" :is-multiline="true" v-model:model-value="localizations[requiredLocalization.iso].description" class="spaced-textbox"/>
                </div>
                <hr class="separator"/>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.restaurantFixedMenuManagementTab.selectFixedMenuProducts') }}</span>
                    <fixed-menu-product-selector :restaurantPresentation="restaurantPresentation" v-model:choices="selectedChoices"/>
                </div>
                <hr class="separator"/>
                <simple-button :text="$t('management.generic.save')" :is-disabled="!dataChanged" @click="updateProduct" :is-loading="isWaitingServerResponse" class="submit-button"/>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CategorySelector from "@/components/inputs/CategorySelector";
import SimpleButton from "@/components/inputs/SimpleButton";
// import SimpleFileUpload from "@/components/inputs/SimpleFileUpload";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
// import AllergenSelector from "@/components/inputs/AllergenSelector";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";
// import VariationSelector from "@/components/inputs/VariationSelector";
import FixedMenuProductSelector from "@/components/inputs/FixedMenuProductSelector";
import { RestaurantProcessor, } from '@/utilities';

export default {
    name: "UpdateFixedMenuModalBox",
    props: {
        categories: {
            type: Array,
            default: () => [],
        },
        restaurantPresentation: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        closeOnProductUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["product-update"],
    components: {
        // SimpleFileUpload,
        CategorySelector,
        // AllergenSelector,
        // VariationSelector,
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        FixedMenuProductSelector,
    },
    data() {
        return {
            price: "",
            vat: "",
            // selectedPreviewImages: [],
            selectedCategories: [],
            // selectedAllergens: [],
            // selectedVariations: [],
            localizations: {},
            selectedChoices: [],
            isWaitingServerResponse: false,
            saved: {},
        };
    },
    methods: {
        async load (product) {
            this.product = product;
            this.price = product.price.toString();
            this.saved.price = this.price;
            this.vat = product.valueAddedTax.toString();
            this.saved.vat = this.vat;

            this.selectedChoices = product.choices.map((choice) => ({
                id: choice.id,
                products: choice.products.map((fixedMenuChoiceProduct) => fixedMenuChoiceProduct.product.id),
            }));
            this.saved.selectedChoices = JSON.parse(JSON.stringify(this.selectedChoices));

            this.product.categories.forEach((category) => {
                this.selectedCategories.push(category.id);
            });
            this.saved.selectedCategories = JSON.parse(JSON.stringify(this.selectedCategories));

            // this.product.allergens.forEach((allergen) => {
            //     this.selectedAllergens.push(allergen.id);
            // });
            // this.saved.selectedAllergens = JSON.parse(JSON.stringify(this.selectedAllergens));

            // this.product.variations.forEach((variation) => {
            //     this.selectedVariations.push(variation.id);
            // });
            // this.saved.selectedVariations = JSON.parse(JSON.stringify(this.selectedVariations));
            this.localizations = this.generateLocalizations();
            this.saved.localizations = JSON.parse(JSON.stringify(this.localizations));
        },
        validateUpdateProductForm() {
            const priceTextbox = this.$refs.priceTextbox;
            const vatTextbox = this.$refs.vatTextBox;

            priceTextbox.clearErrors();
            vatTextbox.clearErrors();

            if (priceTextbox.validate(this.ProductAddErrorType.MISSING_PRICE, this.price.length > 0)) {
                priceTextbox.validate(this.ProductAddErrorType.INVALID_PRICE, Number.parseFloat(this.price) >= 0);
            }

            if (vatTextbox.validate(this.ProductAddErrorType.MISSING_VAT, this.vat.length > 0)) {
                vatTextbox.validate(this.ProductAddErrorType.INVALID_VAT, Number.parseFloat(this.vat) >= 0);
            }

            return (priceTextbox.errors.length + vatTextbox.errors.length) === 0;
        },
        clearProductForm() {
            this.price = "";
            this.selectedCategories = [];
            // this.selectedAllergens = [];
            // this.selectedPreviewImages = [];
            // this.selectedVariations = [];
            this.localizations = {
                en: {},
                zh: {},
                it: {},
            };
            this.selectedChoices = [];
        },
        onPreviewImageUpload(id) {
            this.selectedPreviewImages.push(id);
        },
        async updateProduct() {
            if (!this.validateUpdateProductForm() || this.isWaitingServerResponse) {
                return;
            }

            if (this.selectedCategories.length === 0) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const product = await user.updateFixedMenu({
                    id: this.product.id,
                    archive: true,
                    price: this.price,
                    valueAddedTax: this.vat,
                    restaurantId: this.product.restaurantId,
                    // previewImageId: this.selectedPreviewImages[0] ?? this.product.previewImageId ?? null,
                    categories: this.selectedCategories,
                    // allergens: this.selectedAllergens,
                    // variations: this.selectedVariations,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                    choices: this.selectedChoices,
                });

                if (product) {
                    this.$emit("product-update", product);

                    if (this.closeOnProductUpdate) {
                        this.$refs.window.hide();
                    }

                    this.clearProductForm();
                    notificationCenter.sendSuccessNotification({ text: this.$t('notification.productUpdatedSuccess') });
                } else {
                    notificationCenter.sendFailureNotification({ text: this.$t('notification.serverError') });
                }
            } catch (error) {
                notificationCenter.sendFailureNotification({ text: this.$t('notification.networkError') });
                console.error(error);
            }

            this.isWaitingServerResponse = false;
        },
        generateLocalizations () {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    if (this.product && this.product.localizations) {
                        const existingLocalization = this.product.localizations.find(l => l.languageIso === iso);
                        localizations[iso] = {
                            name: existingLocalization ? existingLocalization.name : '',
                            description: existingLocalization ? existingLocalization.description : ''
                        };
                    } else {
                        localizations[iso] = {
                            name: '',
                            description: ''
                        };
                    }
                });
            }
            return localizations;
        },
    },
    computed: {
        window() {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        ProductAddErrorType() {
            return {
                MISSING_PRICE: {
                    id: 1,
                    text: this.$t('management.generic.missingValue'),
                },
                INVALID_PRICE: {
                    id: 2,
                    text: this.$t('management.generic.invalidValue'),
                },
                MISSING_VAT: {
                    id: 3,
                    text: this.$t('management.generic.missingValue'),
                },
                INVALID_VAT: {
                    id: 4,
                    text: this.$t('management.generic.invalidValue'),
                },
            };
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        },
        dataChanged() {
            if (this.saved.price !== this.price) {
                return true;
            }

            if (this.saved.vat !== this.vat) {
                return true;
            }

            for (const languageIso of Object.keys(this.localizations)) {
                if (this.localizations[languageIso].name !== this.saved.localizations[languageIso].name) {
                    return true;
                }
                if (this.localizations[languageIso].description !== this.saved.localizations[languageIso].description) {
                    return true;
                }
            }
            for (const categoryId of this.selectedCategories) {
                if (!this.saved.selectedCategories.includes(categoryId)) {
                    return true;
                }
            }
            for (const categoryId of this.saved.selectedCategories) {
                if (!this.selectedCategories.includes(categoryId)) {
                    return true;
                }
            }
            if (JSON.stringify(this.saved.selectedChoices) !== JSON.stringify(this.selectedChoices)) {
                return true;
            }

            // for (const allergenId of this.selectedAllergens) {
            //     if (!this.saved.selectedAllergens.includes(allergenId)) {
            //         return true;
            //     }
            // }
            // for (const allergenId of this.saved.selectedAllergens) {
            //     if (!this.selectedAllergens.includes(allergenId)) {
            //         return true;
            //     }
            // }

            // for (const variationId of this.selectedVariations) {
            //     if (!this.saved.selectedVariations.includes(variationId)) {
            //         return true;
            //     }
            // }
            // for (const variationId of this.saved.selectedVariations) {
            //     if (!this.selectedVariations.includes(variationId)) {
            //         return true;
            //     }
            // }

            // if (this.selectedPreviewImages.length > 0) {
            //     return true;
            // }
            return false;
        },
    },
    async mounted() {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
.update-product-modal-box {
    :deep(.modal-box__slot) {
        width: auto;
        min-width: 400px;
        max-width: 80vw;
        min-height: 50vh;
        padding: 30px;
        margin: 30px auto;
        background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }
}

.update-product-form {
    width: 100%;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.separator {
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background-color: #dcdcdc;
}

.input-descriptor-text {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
}

.form-section {
    margin-bottom: 15px;
}

.spaced-textbox {
    margin-top: 10px;
}

.submit-button {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    background-color: $primary-brand-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: $primary-brand-color-hover;
}

.localization-section {
    margin-bottom: 10px;
}
</style>
