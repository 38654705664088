import _ from "lodash";
import { i18n } from "@/main";
import { DiscountStrategies } from "@/products/DiscountStrategies";

export function saveImage (name, imageUrl) {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64data = reader.result;
        localStorage.setItem(name, base64data);
      };
      reader.readAsDataURL(blob);
    });
}

export function loadImage (name) {
  return localStorage.getItem(name);
}

// Update orders in local storage and returns daily orders
export function getLatestOrders () {
  const latestOrders = JSON.parse(window.localStorage.getItem("FortuneRMS::LatestOrders"));
  if (latestOrders) {
    const sortedAndFilteredOrders = latestOrders
      .filter((o) => {
        // get daily orders
        const today = new Date().setHours(0, 0, 0, 0);
        const thatDay = new Date(o.creationTimestamp).setHours(0, 0, 0, 0);
        return today === thatDay;
      })
      .sort((o1, o2) => o2.creationTimestamp - o1.creationTimestamp);
    if (sortedAndFilteredOrders.length === 0) {
      window.localStorage.removeItem("FortuneRMS::LatestOrders");
      return;
    }
    else {
      window.localStorage.setItem("FortuneRMS::LatestOrders", JSON.stringify(sortedAndFilteredOrders));
      return sortedAndFilteredOrders;
    }
  }
}
export class InteractionListener {
  static listen (callback) {
    const listenForInteraction = function (callback) {
      const handleInteraction = _.debounce(() => callback(), 1000);

      window.document.body.addEventListener("mousemove", handleInteraction);
      window.document.body.addEventListener("scroll", handleInteraction);
      window.document.body.addEventListener("keydown", handleInteraction);
      window.document.body.addEventListener("click", handleInteraction);
      window.document.body.addEventListener("touchstart", handleInteraction);
    };

    listenForInteraction(callback);
  }
}

export class MobileViewportSimulator {
  static #isActive = false;

  static update () {
    if (!MobileViewportSimulator.#isActive) {
      return;
    }

    const vh = window.innerHeight * 0.01;

    window.document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  static activate () {
    if (MobileViewportSimulator.#isActive) {
      return;
    }

    MobileViewportSimulator.#isActive = true;

    MobileViewportSimulator.update();
    window.addEventListener("resize", () => MobileViewportSimulator.update());
    window.addEventListener("load", () => MobileViewportSimulator.update());
    window.document.addEventListener("readystatechange", () => MobileViewportSimulator.update());
  }
}

export class CustomerProcessor {
  #customerPresentation;

  constructor({ customerPresentation }) {
    this.#customerPresentation = customerPresentation;
  }
}

export class RestaurantProcessor {
  #restaurantPresentation;
  #customStyleElement;

  constructor({ restaurantPresentation }) {
    this.#restaurantPresentation = restaurantPresentation;
    // Assign popular to products
    if (restaurantPresentation?.activeCategories) {
      for (const category of restaurantPresentation.activeCategories) {
        const categoryProducts = restaurantPresentation.products.filter((p) =>
          p.categories.find(
            (c) => c.id === category.id && c.visible && !c.excludeFromTopSeller
          )
        );
        const top3 = categoryProducts
          .sort(
            (product1, product2) => product2.orderCount - product1.orderCount
          )
          .slice(0, 3);
        let limit = categoryProducts.length > 3 ? 3 : top3.length;
        for (let i = 0; i < limit; i++) {
          const foundProduct = restaurantPresentation.products.find(
            (p) => p.id === top3[i].id
          );
          if (foundProduct) {
            foundProduct.popular = true;
          } else if (limit < top3.length) {
            limit++;
          }
        }
      }
    }
  }

  get restaurant() {
    return this.#restaurantPresentation.restaurant;
  }

  get restaurantConfiguration() {
    return this.#restaurantPresentation?.restaurant?.configuration ?? {};
  }

  get id() {
    return this.#restaurantPresentation.restaurant.id;
  }

  get products() {
    return this.#restaurantPresentation.products;
  }

  get fixedMenus() {
    return this.#restaurantPresentation.fixedMenus;
  }

  get customProducts() {
    return this.#restaurantPresentation.customProducts;
  }

  get categories() {
    return this.#restaurantPresentation.categories;
  }

  get variations() {
    return this.#restaurantPresentation.variations;
  }

  get allergens() {
    return this.#restaurantPresentation.allergens;
  }

  get tables() {
    return this.#restaurantPresentation.tables;
  }

  get scenes() {
    return this.#restaurantPresentation.scenes;
  }

  get productTags() {
    return this.#restaurantPresentation.productTags;
  }

  get baseProducts() {
    return this.products?.filter(
      (product) =>
        !this.productIsFixedMenu(product.id) &&
        !this.productIsCustomProduct(product.id)
    );
  }

  get listedCategories() {
    let activeCategories = this.#restaurantPresentation.activeCategories;
    activeCategories.sort((a, b) => a.index - b.index);

    return activeCategories.map((activeCategory) =>
      this.getCategoryById(activeCategory.id)
    );
  }

  get firstListedCategoryId() {
    return this.listedCategories[0]?.id ?? -1;
  }

  get fixedMenuCategoryId() {
    for (const category of this.listedCategories) {
      const products = this.getProductsByCategoryId(category.id);

      if (products.every((product) => this.productIsFixedMenu(product.id))) {
        return category.id;
      }
    }

    return -1;
  }

  get customProductCategoryId() {
    for (const category of this.listedCategories) {
      const products = this.getProductsByCategoryId(category.id);

      if (
        products.every((product) => this.productIsCustomProduct(product.id))
      ) {
        return category.id;
      }
    }

    return -1;
  }

  getDiscountProductTag() {
    return this.productTags.find((pt) => pt.name === "productTag.discount");
  }

  getNewProductTag() {
    return this.productTags.find((pt) => pt.name === "productTag.new");
  }

  getSortedProductTags() {
    return this.productTags.sort((a, b) => a.priority - b.priority);
  }

  getSortedPopularProductsByCategoryId(id) {
    if (id === 100000) {
      return this.products.filter(
        (product) =>
          product.categories.length > 0 &&
          product.discountStrategies?.length > 0 &&
          (!product.discountExpirationTimestamp ||
            product.discountExpirationTimestamp > Date.now())
      );
    }
    let listedProducts = this.getListedProductsByCategoryId(id);
    const sortedByPopularity = [...listedProducts]
      .filter(
        (p) =>
          p.popular &&
          p.categories.findIndex((c) => c.excludeFromTopSeller) === -1 &&
          p.productTags.length === 0
      )
      .sort((product1, product2) => product2.orderCount - product1.orderCount);
    for (const sbp of sortedByPopularity) {
      const index = listedProducts.findIndex((p) => p.id === sbp.id);
      listedProducts = [{ ...listedProducts[index] }, ...listedProducts];
      listedProducts.splice(index + 1, 1);
    }

    const tags = this.getSortedProductTags();

    tags.forEach((tag) => {
      const sortedByTag = [...listedProducts].filter((p) =>
        p.productTags.find((pt) => String(pt.id) === String(tag.id))
      );
      for (const sbp of sortedByTag) {
        const index = listedProducts.findIndex((p) => p.id === sbp.id);
        listedProducts = [{ ...listedProducts[index] }, ...listedProducts];
        listedProducts.splice(index + 1, 1);
      }
    });
    return listedProducts;
  }

  getSortedPopularProductsByCategoryIdHidedInclude(id) {
    let listedProducts = this.getSortedPopularProductsByCategoryId(id);
    const products = this.getProductsByCategoryId(id);
    const hided = products.filter(
      (product) =>
        !product.categories.find((category) => category.id === id).visible
    );

    return [...listedProducts, ...hided];
  }

  getVisibleProductsByCategoryId(id) {
    const products = this.getProductsByCategoryId(id);
    return products.filter(
      (product) =>
        product.categories.find((category) => category.id === id).visible
    );
  }

  getListedProductsByCategoryId(id) {
    const products = this.getVisibleProductsByCategoryId(id);
    return products.sort(
      (product1, product2) =>
        product2.categories.find((category) => category.id === id).index -
        product1.categories.find((category) => category.id === id).index
    );
  }

  getCategoryById(id) {
    return this.categories.find((category) => category.id === id);
  }

  getProductById(id) {
    return this.products.find((product) => product.id === id);
  }

  getFixedMenuById(id) {
    return this.fixedMenus.find((fixedMenu) => fixedMenu.id === id);
  }

  getCustomProductById(id) {
    return this.customProducts.find((customProduct) => customProduct.id === id);
  }

  getVariationById(id) {
    return this.variations.find((variation) => variation.id === id);
  }

  getAllergenById(id) {
    return this.allergens.find((allergen) => allergen.id === id);
  }

  getSceneById(id) {
    return this.scenes.find((scene) => scene.id === id);
  }

  getProductsByCategoryId(id) {
    return this.products.filter((product) =>
      product.categories.some((category) => category.id === id)
    );
  }

  productIsFixedMenu(id) {
    return this.getFixedMenuById(id) !== undefined;
  }

  productIsCustomProduct(id) {
    return this.getCustomProductById(id) !== undefined;
  }

  getProductLocalization(id, languageIso) {
    return this.getProductById(id)?.localizations?.find(
      (localization) => localization.languageIso === languageIso
    );
  }

  getProductLocalizedName (id) {
    let locale = this.getProductLocalization(id, i18n.global.locale);
    if (locale) {
      return locale.name;
    }
    return this.getProductLocalization(id, "it")?.name ?? "";
  }

  getCategoryLocalization(id, languageIso) {
    return this.getCategoryById(id).localizations.find(
      (localization) => localization.languageIso === languageIso
    );
  }

  getCategoryLocalizedName (id) {
    let locale = this.getCategoryLocalization(id, i18n.global.locale);
    if (locale) {
      return locale.name;
    }
    return this.getCategoryLocalization(id, "it")?.name ?? "";
  }

  getAllergenLocalization(id, languageIso) {
    return this.getAllergenById(id).localizations.find(
      (allergen) => allergen.languageIso === languageIso
    );
  }

  getAllergenLocalizedName (id) {
    let locale = this.getAllergenLocalization(id, i18n.global.locale);
    if (locale) {
      return locale.name;
    }
    return this.getAllergenLocalization(id, "it")?.name ?? "";
  }

  getVariationLocalization(id, languageIso) {
    return this.getVariationById(id).localizations.find(
      (localization) => localization.languageIso === languageIso
    );
  }

  getVariationPrice(id) {
    return this.getVariationById(id).price;
  }

  getVariationLocalizedName (id) {
    let locale = this.getVariationLocalization(id, i18n.global.locale);
    if (locale) {
      return locale.name;
    }
    return this.getVariationLocalization(id, "it")?.name ?? "";
  }

  getProductVariations(id) {
    return this.variations.filter((variation) =>
      this.getProductById(id).variations.some(
        (productVariation) => variation.id === productVariation.id
      )
    );
  }

  productHasVariations(id) {
    return this.getProductVariations(id).length > 0;
  }

  getTableNameById(id) {
    return this.tables.find((table) => table.id === id).name ?? "";
  }

  getTableIdByName(name) {
    return this.tables.find((table) => table.name === name)?.id;
  }

  getCustomStyleElement() {
    if (this.#customStyleElement) {
      return this.#customStyleElement;
    }

    const style = window.document.createElement("style");

    style.appendChild(
      window.document.createTextNode(
        this.restaurantConfiguration.customStyle ?? ""
      )
    );

    this.#customStyleElement = style;

    return style;
  }

  getSceneLocalization(id, languageIso) {
    return this.getSceneById(id).localizations.find(
      (scene) => scene.languageIso === languageIso
    );
  }

  getSceneLocalizedName (id) {
    let locale = this.getSceneLocalization(id, i18n.global.locale);
    if (locale) {
      return locale.name;
    }
    return this.getSceneLocalization(id, "it")?.name ?? "";
  }

  getUserDisplayLocalizations() {
    if (!this.restaurantConfiguration || !this.restaurantConfiguration.userDisplayLocalizations) {
      return [];
    }
    return typeof this.restaurantConfiguration.userDisplayLocalizations === "string"
      ? JSON.parse(this.restaurantConfiguration.userDisplayLocalizations)
      : this.restaurantConfiguration.userDisplayLocalizations;
  }
}

export class BasketProcessor {
  #basket;
  #restaurantProcessor;
  #memory;

  constructor({ basket, restaurantProcessor }) {
    this.#basket = basket;
    this.#restaurantProcessor = restaurantProcessor;
    this.#memory = {};
  }

  get selectedProductsIds () {
    return Object.keys(this.#basket.selectedProducts)
      .filter((id) => this.#basket.selectedProducts[id].selectedVolume > 0)
      .map((plainId) => Number.parseInt(plainId));
  }

  get selectedFreeProductsIds () {
    return Object.keys(this.#basket.selectedFreeProducts)
      .filter((id) => this.#basket.selectedFreeProducts[id].selectedVolume > 0)
      .map((plainId) => Number.parseInt(plainId));
  }

  get selectedProducts () {
    return this.selectedProductsIds.map((id) =>
      this.#restaurantProcessor.getProductById(id)
    );
  }

  get selectedFreeProducts () {
    return this.selectedFreeProductsIds.map((id) =>
      this.#restaurantProcessor.getProductById(id)
    );
  }

  get selectedBaseProducts () {
    return this.selectedProductsIds
      .filter(
        (id) =>
          !this.#restaurantProcessor.productIsFixedMenu(id) &&
          !this.#restaurantProcessor.productIsCustomProduct(id) &&
          !this.customProductOwnsProduct(id)
      )
      .map((id) => this.#restaurantProcessor.getProductById(id));
  }

  get selectedFixedMenus () {
    return this.selectedProductsIds
      .filter((id) => this.#restaurantProcessor.productIsFixedMenu(id))
      .map((id) => this.#restaurantProcessor.getFixedMenuById(id));
  }

  get selectedCustomProducts () {
    return this.selectedProductsIds
      .filter((id) => this.#restaurantProcessor.productIsCustomProduct(id))
      .map((id) => this.#restaurantProcessor.getCustomProductById(id));
  }

  customProductOwnsProduct (id) {
    let isOwned = false;

    this.selectedCustomProducts.forEach((customProduct) => {
      customProduct.choices?.forEach((choice) => {
        choice?.products?.forEach((product) => {
          if (product.product.id === id) {
            isOwned = true;
          }
        });
      });
    });

    return isOwned;
  }

  get appliedAdditions () {
    return this.#basket.additions;
  }

  get appliedDeductions () {
    return this.#basket.deductions;
  }

  get isEmpty () {
    return this.selectedProductsIds.length === 0;
  }

  get totalSelectedVolume () {
    return Object.keys(this.#basket.selectedProducts).reduce(
      (total, productId) =>
        total + this.#basket.selectedProducts[productId].selectedVolume,
      0
    );
  }

  get variationsTotalPrice () {
    return Object.keys(this.#basket.selectedProductsVariations).reduce(
      (totalPrice, productId) =>
        totalPrice + this.getProductVariationsTotalPrice(productId),
      0
    );
  }

  get discountedTotalPrice () {
    const result = this.selectedProducts.reduce(
      (totalPrice, product) =>
        totalPrice + this.getProductDiscountedTotalPrice(product.id),
      0
    );
    return result;
  }

  get subtotal () {
    return (
      this.selectedProducts.reduce(
        (subtotal, product) =>
          (this.#restaurantProcessor.productIsCustomProduct(product.id)
            ? 1
            : this.getProductSelectedVolume(product.id)) *
          (this.#restaurantProcessor.productIsCustomProduct(product.id)
            ? this.getCustomProductPriceById(product.id)
            : product.price) +
          subtotal,
        0
      ) +
      this.variationsTotalPrice +
      this.discountedTotalPrice +
      this.totalAddition +
      this.totalDeduction
    );
  }

  get discountPercentage () {
    return Math.min(
      this.#basket.discounts.reduce(
        (percentage, discount) => percentage + discount.percentage,
        0
      ),
      100
    );
  }

  get discount () {
    const discountPercentage = this.discountPercentage;

    if (discountPercentage === 0) {
      return 0;
    }

    return (discountPercentage * this.subtotal) / 100;
  }

  get totalDeduction () {
    return this.#basket.deductions.reduce(
      (total, deduction) => total + deduction.value,
      0
    );
  }

  get totalAddition () {
    return this.#basket.additions.reduce(
      (total, addition) => total + addition.value,
      0
    );
  }

  get isDiscounted () {
    return this.discount > 0;
  }

  get total () {
    return Math.max(0, this.subtotal - this.discount);
  }

  getProductSelectedVolume (id) {
    if (!this.#basket.selectedProducts[id]) {
      return 0;
    }
    return this.#basket.selectedProducts[id].selectedVolume;
  }

  getFreeProductSelectedVolume (id) {
    return this.#basket.selectedFreeProducts[id]?.selectedVolume ?? 0;
  }

  getProductVariationsTotalPrice (id) {
    if (
      !this.#basket.selectedProductsVariations[id] ||
      typeof this.#basket.selectedProductsVariations[id][Symbol.iterator] !==
      "function"
    ) {
      return 0;
    }
    const totalVariations = this.#basket.selectedProductsVariations[id] ?? [];
    let totalPrice = 0;

    for (const volumeVariations of totalVariations) {
      totalPrice += volumeVariations.reduce(
        (total, variationId) =>
          total + this.#restaurantProcessor.getVariationById(variationId).price,
        0
      );
    }

    return totalPrice;
  }

  getProductDiscountedTotalPrice (id) {
    const product = this.#restaurantProcessor.getProductById(id);
    if (
      !product ||
      !product.discountStrategies ||
      (product.discountExpirationTimestamp &&
        product.discountExpirationTimestamp < Date.now())
    ) {
      return 0;
    }
    const discountStrategies = product.discountStrategies;
    let totalPrice = 0;

    if (discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
      totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
        this.getProductSelectedVolume(id),
        product.price
      );
    }

    const fixedDiscount = discountStrategies.find((s) =>
      String(s).startsWith("-")
    );
    const fixed = Boolean(fixedDiscount);
    if (fixed) {
      const fixedValue = fixedDiscount.substring(1);
      totalPrice -= DiscountStrategies.fixed.applyDiscount(
        this.getProductSelectedVolume(id),
        product.price,
        fixedValue
      );
    }

    const percentageDiscount = discountStrategies.find((s) =>
      String(s).startsWith("%")
    );
    const percentage = Boolean(percentageDiscount);
    if (percentage) {
      const percentageValue = percentageDiscount.substring(1);
      totalPrice -= DiscountStrategies.percentage.applyDiscount(
        this.getProductSelectedVolume(id),
        product.price,
        percentageValue
      );
    }

    return totalPrice;
  }

  getSingleProductDiscountedPrice (id) {
    const product = this.#restaurantProcessor.getProductById(id);
    if (
      !product ||
      !product.discountStrategies ||
      (product.discountExpirationTimestamp &&
        product.discountExpirationTimestamp < Date.now())
    ) {
      return 0;
    }
    const discountStrategies = product.discountStrategies;
    let price = 0;

    const fixedDiscount = discountStrategies.find((s) =>
      String(s).startsWith("-")
    );
    const fixed = Boolean(fixedDiscount);
    if (fixed) {
      const fixedValue = fixedDiscount.substring(1);
      price -= DiscountStrategies.fixed.applyDiscount(
        1,
        product.price,
        fixedValue
      );
    }

    const percentageDiscount = discountStrategies.find((s) =>
      String(s).startsWith("%")
    );
    const percentage = Boolean(percentageDiscount);
    if (percentage) {
      const percentageValue = percentageDiscount.substring(1);
      price -= DiscountStrategies.percentage.applyDiscount(
        1,
        product.price,
        percentageValue
      );
    }

    return price;
  }

  getSelectedProductPriceAfterVariations (id) {
    return (
      this.getProductSelectedVolume(id) *
      this.#restaurantProcessor.getProductById(id).price +
      this.getProductDiscountedTotalPrice(id) +
      this.getProductVariationsTotalPrice(id)
    );
  }

  productHasVariationsAffectingPrice (id) {
    return this.getProductVariationsTotalPrice(id) !== 0;
  }

  productHasDiscountAffectingPrice (id) {
    return this.getProductDiscountedTotalPrice(id) !== 0;
  }

  getCustomProductPriceById (id) {
    const productsPrices = [];
    const productContainer = {};

    this.#basket.selectedProducts[id].selectedProducts.forEach((products) => {
      products.forEach((customProductProduct) => {
        Object.entries(customProductProduct).forEach((e) => {
          productContainer.id = Number.parseInt(e[0]);
          productContainer.price = e[1].price;
          productContainer.selectedVolume = e[1].selectedVolume;
          productsPrices.push({ ...productContainer });
        });
      });
    });

    return productsPrices.reduce(
      (subtotal, product) => product.selectedVolume * product.price + subtotal,
      0
    );
  }

  unselectProducts () {
    this.#memory.selectedProducts = this.#basket.selectedProducts;
    this.#memory.selectedFreeProducts = this.#basket.selectedFreeProducts;
    this.#memory.selectedFixedMenus = this.#basket.selectedFixedMenus;
    this.#basket.selectedProducts = {};
    this.#basket.selectedFreeProducts = {};
    this.#basket.selectedFixedMenus = {};
  }

  reselectProducts () {
    this.#basket.selectedProducts = this.#memory.selectedProducts;
    this.#basket.selectedFreeProducts = this.#memory.selectedFreeProducts;
    this.#basket.selectedFixedMenus = this.#memory.selectedFixedMenus;
    // this.#memory = {};
  }

  static createEmpty () {
    return {
      discounts: [],
      additions: [],
      deductions: [],
      tables: [],
      requestedDeliveryTimestamp: null,
      requestedPersons: null,
      selectedProducts: {},
      selectedFreeProducts: {},
      selectedFixedMenus: {},
      selectedProductsVariations: {},
      selectedProductsMessages: {},
      isTakeAway: false,
      deliveryReceiver: {
        fullName: "",
        mobilePhone: "",
        streetName: "",
        buildingCode: "",
        postalCode: "",
      },
      message: "",
    };
  }
}

export class GoogleMapsApi {
  #key;
  #language;

  constructor({ key, language, }) {
    this.#key = key; // API key
    this.#language = language; // Script language that changes response format. For example: "it-IT"
  }

  async init () {
    return new Promise((resolve) => {
      const scriptId = "map-api-script";
      const mapAlreadyAttached = !!document.getElementById(scriptId);
      if (mapAlreadyAttached && window.google) {
        resolve(mapAlreadyAttached);
      }
      else {
        window.mapApiInitialized = () => resolve(mapAlreadyAttached);
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.#key}&callback=mapApiInitialized&language=${this.#language}`;
        script.async = true;
        document.body.appendChild(script);
      }
    });
  }

  async getDistance ({ origin, destination, }) {
    return new Promise((resolve, reject) => {
      let travelDistanceText, travelDistanceValue, travelDurationText, travelDurationValue;
      const distanceMatrixService = new window.google.maps.DistanceMatrixService();
      const request = {
        origins: [origin,],
        destinations: [destination,],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      };
      const callback = function (response, status) {
        if (status === "OK") {
          const distances = response.rows[0].elements;
          const distResult = distances[0];

          if (distResult.status === "OK") {
            travelDistanceText = distResult.distance.text;
            travelDistanceValue = distResult.distance.value;
            travelDurationText = distResult.duration.text;
            travelDurationValue = distResult.duration.value;
            resolve({ travelDistanceText, travelDistanceValue, travelDurationText, travelDurationValue });
          }
          else {
            reject();
          }
        }
        else {
          reject();
        }
      };

      distanceMatrixService.getDistanceMatrix(request, callback);
    });
  }
}

export class WSKeepAlive {
  #apiURI;
  #ws;
  #alive;
  #eventListenerMap;

  constructor(apiURI) {
    this.#ws = new WebSocket(apiURI);
    this.#apiURI = apiURI;
    this.#eventListenerMap = new Map();
  }

  get ws () {
    return this.#ws;
  }

  get readyState () {
    return this.#ws.readyState;
  }

  send (message) {
    this.#ws.send(message);
  }

  addEventListener (event, handler) {
    this.#ws.addEventListener(event, handler);
    this.#eventListenerMap.set(event, handler);
  }

  close () {
    clearInterval(this.#alive);
    this.#ws.close();
  }

  keepAlive (socketConnectedHandler) {
    this.#alive = setInterval(() => {
      this.reconnect(socketConnectedHandler);
    }, 1000 * 5);
  }

  reconnect () {
    if (!this.#ws || (this.#ws && (this.#ws.readyState === WebSocket.CLOSED || this.#ws.readyState === WebSocket.CLOSING))) {
      this.#ws = new WebSocket(this.#apiURI);

      for (const [event, handler,] of this.#eventListenerMap.entries()) {
        this.#ws.addEventListener(event, handler);
      }
    }
  }
}


export function getUserSelectedFilters (tabName) {
  let result = null;
  try {
    const saved = sessionStorage.getItem(tabName + "_cache");
    if (!saved) {
      return null;
    }
    const item = JSON.parse(saved);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(tabName);
    }
    else {
      result = item.value;
    }
  }
  catch (e) { console.log(e) }
  return result;
}

export function setUserSelectedFilters (tabName, data, ttl = 600) {
  const now = new Date();
  const item = {
    value: data,
    expiry: now.getTime() + ttl * 1000,
  };
  sessionStorage.setItem(tabName + "_cache", JSON.stringify(item));
}