import VueApexCharts from "vue3-apexcharts";
import { en } from "@/locale/en";
import { it } from "@/locale/it";
import { zh } from "@/locale/zh";
import { es } from "@/locale/es";
import { router } from "@/router";
import VueSplide from "@splidejs/vue-splide";
import { user, } from "@/user";
import { createApp } from 'vue';
import App from "./Application";
import { createI18n } from "vue-i18n";
import VueEasyLightbox from "vue-easy-lightbox";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { isProduction } from "./configuration";
if (!isProduction) {
    console.log("Production: ", isProduction);
}

const app = createApp(App);

const DESKTOP_USER_AGENT_NAME = "FortuneRMS/Desktop";
const KIOSK_USER_AGENT_NAME = "FortuneRMS/Kiosk";

const userAgent = (() => {
    try {
        return JSON.parse(window.navigator.userAgent);
    }
    catch {
        return {};
    }
})();

const preloadedImages = new Map();

export function preloadImage (uri) {
    if (preloadedImages.has(uri)) {
        return;
    }

    const image = new Image();

    image.src = uri;

    preloadedImages.set(uri, image);
}

export const isKiosk = userAgent.name === KIOSK_USER_AGENT_NAME || userAgent.mode === "kiosk";
export const isDesktop = userAgent.name === DESKTOP_USER_AGENT_NAME;
export const isBrowser = !(isKiosk && isDesktop);
export const isMobile = window.innerWidth <= 448;

app.mixin({
    methods: {
        normalizePriceToDisplay (price, precision = 2) {
            return price.toFixed(precision).replace(".", ",")
        },

        getElementAbsoluteHeight (element) {
            const computedStyle = window.getComputedStyle(element);
            const height = Number.parseFloat(computedStyle.getPropertyValue("height"));
            const marginTop = Number.parseFloat(computedStyle.getPropertyValue("margin-top"));
            const marginBottom = Number.parseFloat(computedStyle.getPropertyValue("margin-bottom"));
            const paddingTop = Number.parseFloat(computedStyle.getPropertyValue("padding-top"));
            const paddingBottom = Number.parseFloat(computedStyle.getPropertyValue("padding-bottom"));

            return height + marginTop + marginBottom + paddingTop + paddingBottom;
        },

        getElementInnerAbsoluteHeight (element) {
            let height = 0;

            for (const children of element.children) {
                height += this.getElementAbsoluteHeight(children);
            }

            return height;
        },
    },
    mounted () {
        this.$el.__vue__ = this;
    }
});

export const defaultLocale = userAgent.locale ?? (window?.navigator?.language ?? "en").substr(0, 2);
export const i18n = new createI18n({
    locale: defaultLocale,
    allowComposition: true,
    messages: {
        en,
        zh,
        it,
        es,
    },
});

app.use(VueApexCharts);
app.use(router);
app.use(i18n);
app.use(VueSplide);
app.use(VueEasyLightbox);
app.component(VueCountdown.name, VueCountdown);

if (userAgent.authenticationToken) {
    user.setAuthenticationToken(userAgent.authenticationToken);
}

if (
    !isKiosk &&
    !isDesktop &&
    !window.location.pathname.endsWith("pou") &&
    isProduction
) {
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.text = `
    var _iub = _iub || [];
    _iub.csConfiguration = {"siteId":3771754,"cookiePolicyId":95253192,"lang":"it"};
  `;
    document.head.appendChild(script1);
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://cs.iubenda.com/autoblocking/3771754.js";
    document.head.appendChild(script2);
    const script3 = document.createElement("script");
    script3.type = "text/javascript";
    script3.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    script3.charset = "UTF-8";
    script3.async = true;
    document.head.appendChild(script3);
    const script4 = document.createElement("script");
    script4.type = "text/javascript";
    script4.text = `
    var _iub = _iub || {}; 
    _iub.cons_instructions = _iub.cons_instructions || []; 
    _iub.cons_instructions.push(["init", {api_key: "TOOkkalJ64CXZlZKy9hUgJQrsmUmN5Ht"}]);
  `;
    document.head.appendChild(script4);
    const script5 = document.createElement("script");
    script5.type = "text/javascript";
    script5.src = "https://cdn.iubenda.com/cons/iubenda_cons.js";
    script5.async = true;
    document.head.appendChild(script5);
}

app.mount("#app");
