<template>
    <div class="flex flex--100 cashier-sale-point-view">
        <simple-language-selector :available-languages="displayLanguages"/>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
            <primary-logo/>
            <h2 class="restaurant-name">{{ restaurant.name }}, {{ restaurant.address }}</h2>
        </div>
        <div class="flex flex--100 flex--y-align-end main">
            <restaurant-product-list-vertical
                ref="restaurantProductList"
                :restaurant-presentation="restaurantPresentation"
                layout="horizontal"
                :show-search-bar="true"
                v-model:selected-products="basket.selectedProducts"
                @increase-volume="onProductIncreaseVolume"
                @updateVolume="updateSingleProductDiscount"
            />
            <customer-basket ref="customerBasket" :restaurant-presentation="restaurantPresentation" v-model:basket="basket" @updateVolume="updateSingleProductDiscount"/>
            <div class="flex flex--100 flex--y-align-center action-box">
                <div class="flex action-buttons">
                    <!-- <simple-button :text="$t('order.verifyDeliveryAddress')" @click="showCustomerDistanceModalBox"/> -->
                    <simple-button :text="$t('generic.clear')" :is-disabled="basketIsEmpty" @click="clearOrder"/>
                    <simple-button :text="$t('generic.continue')" :is-disabled="basketIsEmpty" @click="showCheckoutModalBox"/>
                </div>
                <div class="flex promo-code flex--x-align-center ">
                    <!-- <simple-button :text="$t('order.verifyDeliveryAddress')" @click="showCustomerDistanceModalBox"/> -->
                     <simple-textbox :placeholder="$t('memberOnly.promoCode').toUpperCase()"
                            v-model:model-value="promoCode" />
                    <simple-button class="promo-code-button" :text="$t('memberOnly.check')" @click="usePromoCode" />
                    <span class="flex flex--100 flex--x-align-center">{{  promoCodeReply }}</span>
                </div>
            </div>
        </div>
        <div class="flex flex--100 footer">
            <div class="flex flex--30 flex--x-align-center flex--y-align-center" style="height: 100%;">
                <span class="legal-link" @click="showPickUpModalBox">
                    {{ $t("pickUp.restaurantInfo") }}
                </span>
                <span class="legal-link">
                    <privacy-policy/>
                </span>
                <span class="legal-link">
                    <cookie-policy/>
                </span>
            </div>
        </div>
        <modal-box
            class="checkout-modal-box"
            :show-close-button="false"
            :close-on-background-click="false"
            ref="checkoutModalBox"
        >
            <customer-order-checkout
                ref="customerOrderCheckout"
                :restaurant-presentation="restaurantPresentation"
                :skip-delivery-state="false"
                :promo-code="promoCode"
                :global-discount="globalDiscount"
                :preset-order-delivery-location="orderDeliveryLocation"
                v-model:basket="basket"
                @cancel="hideCheckoutModalBox"
                @complete="onCheckoutComplete"
                @close="hideCheckoutModalBox"
            />
        </modal-box>
        <pick-up-modal-box :restaurant-presentation="restaurantPresentation" ref="pickUpModalBox" @delivery="updateDeliveryMode"/>
        <!-- <customer-distance-modal-box :restaurant-presentation="restaurantPresentation" ref="customerDistanceModalBox" @valid="onAddressValidated"/> -->
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CustomerBasket from "@/components/CustomerBasket";
import CustomerOrderCheckout from "@/components/CustomerOrderCheckout";
import SimpleLanguageSelector from "@/components/inputs/SimpleLanguageSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
// import CustomerDistanceModalBox from "@/components/modals/CustomerDistanceModalBox";
import PickUpModalBox from "@/components/modals/PickUpModalBox";
import RestaurantProductListVertical from "@/components/RestaurantProductListVertical";
import CookiePolicy from "@/components/utilities/legal/CookiePolicy";
import PrivacyPolicy from "@/components/utilities/legal/PrivacyPolicy";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import {i18n} from "@/main";
import { OrderPaymentMethod, OrderDeliveryLocation, } from "@/orders/RestaurantOrder";
import { server } from "@/server";
import { RestaurantProcessor, BasketProcessor, } from "@/utilities";
import { cloneDeep } from "lodash";
import { DiscountStrategies } from "@/products/DiscountStrategies";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";

const preloadedData = {};
let presentation = {};

function getLastBasket (restaurantId) {
    const plainLastBasket = window.localStorage.getItem(`FortuneRMS::${restaurantId}::LastBasket`);

    if (typeof plainLastBasket !== "string") {
        return undefined;
    }

    try {
        return JSON.parse(plainLastBasket);
    }
    catch {
        return undefined;
    }
}

export default {
    async beforeRouteEnter (to, from, next) {
        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId);
        presentation = cloneDeep(preloadedData.restaurantPresentation);
        i18n.locale = (window.navigator.language ?? "").split("-")[0].toLowerCase();

        if (!preloadedData.restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }

        next();
    },

    name: "WebOrderView",
    components: {
        CookiePolicy,
        PrivacyPolicy,
        CustomerOrderCheckout,
        // CustomerDistanceModalBox,
        PrimaryLogo,
        SimpleLanguageSelector,
        ModalBox,
        SimpleButton,
        CustomerBasket,
        RestaurantProductListVertical,
        PickUpModalBox,
        SimpleTextbox,
    },
    data () {
        return {
            OrderDeliveryLocation,
            OrderPaymentMethod,
            basket: getLastBasket(preloadedData.restaurantPresentation.restaurant.id) ?? BasketProcessor.createEmpty(),

            date: new Date(),

            addressValue: "",
            houseNumberValue: "",
            capValue: "",

            fullNameValue: "",
            mobileNumberValue: "",

            ordersToPay: [],
            ordersInPreparation: [],
            completedOrders: [],

            isWaitingServerResponseForOrderConfirmation: false,

            orderOrigin: null,
            orderPaymentMethod: null,
            orderDeliveryLocation: OrderDeliveryLocation.ADDRESS,

            confirmedOrder: null,

            deliveryTimestampOptions: [ {
                text: "Immediatamente",
                value: -1,
            }, {
                text: "17:00",
                value: 0,
            }, {
                text: "17:15",
                value: 1,
            }, {
                text: "17:30",
                value: 2,
            }, {
                text: "18:00",
                value: 3,
                },],
            isWaitingServerResponseForPromoCode: false,
            promoCode: "",
            globalDiscount: "",
            promoCodeReply: "",
        };
    },
    methods: {
        clearOrder () {
            this.basket = BasketProcessor.createEmpty();

            window.localStorage.removeItem(`FortuneRMS::${this.restaurantId}::LastBasket`);
        },

        onAddressValidated (addressDescriptor) {
            this.addressValue = addressDescriptor.address;
            this.houseNumberValue = addressDescriptor.houseNumber;
            this.capValue = addressDescriptor.cap;

            const basketCopy = cloneDeep(this.basket);

            basketCopy.deliveryReceiver.streetName = this.addressValue;
            basketCopy.deliveryReceiver.buildingCode = this.houseNumberValue;
            basketCopy.deliveryReceiver.postalCode = this.capValue;

            this.basket = basketCopy;

            window.localStorage.setItem(`FortuneRMS::Restaurant::${this.restaurantId}::LastValidAddress`, JSON.stringify(addressDescriptor));
        },

        onCheckoutComplete () {
            this.clearOrder();
            this.promoCode = "";
            this.promoCodeReply = "";
            this.globalDiscount = "";
            this.resetGlobalDiscount();
        },

        showCheckoutModalBox () {
            this.$refs.checkoutModalBox.show();
        },

        hideCheckoutModalBox () {
            this.$refs.checkoutModalBox.hide();
        },

        onConfirmationModalBoxHide () {
            this.orderPaymentMethod = null;
            this.confirmedOrder = null;
        },

        showPickUpModalBox () {
            this.$refs.pickUpModalBox.show();
        },

        showCustomerDistanceModalBox () {
            this.$refs.customerDistanceModalBox.show();
        },

        getDeliveryLocationButtonModifiers (location) {
            return {
                "payment-method-button--selected": this.orderDeliveryLocation === location,
            };
        },

        showOrderConfirmationModalBox () {
            this.$refs.orderConfirmationModalBox.show();
        },

        selectAddressDeliveryLocation () {
            this.orderDeliveryLocation = OrderDeliveryLocation.ADDRESS;
        },

        selectTakeAwayDeliveryLocation () {
            this.orderDeliveryLocation = OrderDeliveryLocation.TAKE_AWAY;
        },

        selectTableDeliveryLocation () {
            this.orderDeliveryLocation = OrderDeliveryLocation.RESTAURANT;
        },

        selectCardPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.CARD;
        },

        onProductIncreaseVolume (product) {
            if (product.variations.length > 0) {
                this.$refs.customerBasket.showProductVariationModalBox(product);
            }
        },
        updateDeliveryMode (delivery) {
            this.orderDeliveryLocation = delivery;
        },

        async usePromoCode () {
            if (this.isWaitingServerResponseForPromoCode) {
                return;
            }
            this.isWaitingServerResponseForPromoCode = true;
            try {
                const response = await server.checkPromoCode({ code: this.promoCode, });
                const expiryDate = response.expiryDate ? new Date(response.expiryDate) : null;
                const currentDate = new Date();
                const hasExpiration = Boolean(response.expiryDate);
                if (response && response.isActive && (!hasExpiration || currentDate < expiryDate)) {
                    this.promoCodeReply = "OK";
                    if (response.discountPercentage) {
                        this.globalDiscount = response.discountPercentage;
                        this.applyGlobalDiscount();
                    }
                }
                else {
                    this.promoCodeReply = this.$t("memberOnly.invalid");
                }
            }
            catch (e) {
                console.log(e);
            }
            this.isWaitingServerResponseForPromoCode = false;
        },

        async updateSingleProductDiscount ({ id, volume }) {
            if (this.globalDiscount.length > 0) {
                let ds = null;
                const discountPercentage = Number.parseFloat(this.globalDiscount);
                if (this.restaurantProcessor.productIsFixedMenu(id)) {
                    const fixedMenu = this.restaurantProcessor.getFixedMenuById(id);
                    const originalFixedMenu = presentation.fixedMenus.find((p) => p.id === id);
                    const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                        Math.max(volume, 1),
                        fixedMenu.price,
                        discountPercentage
                    );
                    const normalDiscount = this.getDiscountedProductPrice(originalFixedMenu, volume);
                    if (estimateDiscount < normalDiscount) {
                        if (fixedMenu.discountStrategies.length > 0) {
                            fixedMenu.discountStrategies[0] = `%${this.globalDiscount}`;
                        }
                        else {
                            fixedMenu.discountStrategies = [`%${this.globalDiscount}`];
                        }
                        fixedMenu.discountExpirationTimestamp = null;
                    }
                    else {
                        fixedMenu.discountStrategies = cloneDeep(originalFixedMenu.discountStrategies);
                        fixedMenu.discountExpirationTimestamp = originalFixedMenu.discountExpirationTimestamp;
                    }
                    ds = cloneDeep(fixedMenu.discountStrategies);
                }
                else {
                    const product = this.restaurantProcessor.getProductById(id);
                    const originalProduct = presentation.products.find((p) => p.id === id);
                    const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                        Math.max(volume, 1),
                        product.price,
                        discountPercentage
                    );
                    const normalDiscount = this.getDiscountedProductPrice(originalProduct, volume);
                    if (estimateDiscount < normalDiscount) {
                        if (product.discountStrategies.length > 0) {
                            product.discountStrategies[0] = `%${this.globalDiscount}`;
                        }
                        else {
                            product.discountStrategies = [`%${this.globalDiscount}`];
                        }
                        product.discountExpirationTimestamp = null;
                    }
                    else {
                        product.discountStrategies = cloneDeep(originalProduct.discountStrategies);
                        product.discountExpirationTimestamp = originalProduct.discountExpirationTimestamp;
                    }
                    ds = cloneDeep(product.discountStrategies);
                }
                this.basketProcessor.unselectProducts();
                await this.$nextTick();
                this.basketProcessor.reselectProducts();
                await this.$refs.restaurantProductList.forceUpdateById(id, ds);
            }
        },

        async applyGlobalDiscountSafe () {
            await this.resetGlobalDiscount();
            await this.applyGlobalDiscount();
        },

        async applyGlobalDiscount () {
            const products = this.restaurantPresentation.products;
            const fixedMenus = this.restaurantPresentation.fixedMenus;
            const discountPercentage = Number.parseFloat(this.globalDiscount);
            products.forEach((p) => {
                const volume = this.basketProcessor.getProductSelectedVolume(p.id) || 1;
                const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                    volume,
                    p.price,
                    discountPercentage
                );
                const normalDiscount = this.getDiscountedProductPrice(p, volume);
                if (estimateDiscount < normalDiscount) {
                    if (p.discountStrategies.length > 0) {
                        p.discountStrategies[0] = `%${this.globalDiscount}`;
                    }
                    else {
                        p.discountStrategies = [`%${this.globalDiscount}`];
                    }
                    p.discountExpirationTimestamp = null;
                }
            });
            fixedMenus.forEach((p) => {
                const volume = this.basketProcessor.getProductSelectedVolume(p.id) || 1;
                const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                    volume,
                    p.price,
                    discountPercentage
                );
                const normalDiscount = this.getDiscountedProductPrice(p, volume);
                if (estimateDiscount < normalDiscount) {
                    if (p.discountStrategies.length > 0) {
                        p.discountStrategies[0] = `%${this.globalDiscount}`;
                    }
                    else {
                        p.discountStrategies = [`%${this.globalDiscount}`];
                    }
                    p.discountExpirationTimestamp = null;
                }
            });
            this.basketProcessor.unselectProducts();
            await this.$nextTick();
            this.basketProcessor.reselectProducts();
            await this.$refs.restaurantProductList.forceUpdate();
        },

        async removeGlobalDiscount () {
            if (this.globalDiscount.length > 0) {
                this.globalDiscount = "";
                await this.$nextTick();
                await this.resetGlobalDiscount();
            }
        },

        async resetGlobalDiscount () {
            const products = this.restaurantPresentation.products;
            const fixedMenus = this.restaurantPresentation.fixedMenus;

            products.forEach((p) => {
                const original = presentation.products.find((pp) => pp.id === p.id);
                p.discountStrategies = cloneDeep(original.discountStrategies);
                p.discountExpirationTimestamp = original.discountExpirationTimestamp;
            });
            fixedMenus.forEach((p) => {
                const original = presentation.fixedMenus.find((pf) => pf.id === p.id);
                p.discountStrategies = cloneDeep(original.discountStrategies);
                p.discountExpirationTimestamp = original.discountExpirationTimestamp;
            });
            this.basketProcessor.unselectProducts();
            await this.$nextTick();
            this.basketProcessor.reselectProducts();
            await this.$refs.restaurantProductList.forceUpdate();
        },

        getDiscountedProductPrice (product, volume) {
            if (!product.discountStrategies || product.discountExpirationTimestamp && (Number.parseInt(product.discountExpirationTimestamp) < Date.now())) {
                return 0;
            }
            let totalPrice = 0;

            if (product.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
                totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                    volume,
                    product.price,
                );
            }

            const fixedDiscount = product.discountStrategies.find((s) =>
                String(s).startsWith("-")
            );
            const fixed = Boolean(fixedDiscount);
            if (fixed) {
                const fixedValue = fixedDiscount.substring(1);
                totalPrice -= DiscountStrategies.fixed.applyDiscount(
                    volume,
                    product.price,
                    fixedValue
                );
            }

            const percentageDiscount = product.discountStrategies.find((s) =>
                String(s).startsWith("%")
            );
            const percentage = Boolean(percentageDiscount);
            if (percentage) {
                const percentageValue = percentageDiscount.substring(1);
                totalPrice -= DiscountStrategies.percentage.applyDiscount(
                    volume,
                    product.price,
                    percentageValue
                );
            }

            return totalPrice;
        },
    },
    computed: {
        restaurantPresentation () {
            // filter product 637 for 1 month
            const restaurantPresentation = {
                ...preloadedData.restaurantPresentation,
                products: preloadedData.restaurantPresentation.products.filter((p) => !(p.id == 2813 || p.id == 2812)),
            }
            return restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurant () {
            return this.restaurantProcessor.restaurant;
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },

        displayLanguages () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketSelectedFixedMenus() {
            return this.basketProcessor.selectedFixedMenus;
        },

        basketTotalSelectedVolume () {
            return this.basketProcessor.totalSelectedVolume;
        },

        basketSubtotal () {
            return this.basketProcessor.subtotal;
        },

        basketTotalAddition () {
            return this.basketProcessor.totalAddition;
        },

        basketTotal () {
            return this.basketProcessor.total;
        },

        basketIsEmpty () {
            return this.basketProcessor.isEmpty;
        },
        // </basket>

        availableDeliveryTimestamps () {
            const deliveryTimestamps = [];/*
            const fifteenMinutesInMilliseconds = 900000;
            const currentTimestamp = this.date.getTime();*/

            return deliveryTimestamps;
        },

        lastValidAddress () {
            return window.localStorage.getItem(`FortuneRMS::Restaurant::${this.restaurantId}::LastValidAddress`);
        },

        hasLastValidAddress () {
            return this.lastValidAddress !== null;
        },
    },
    watch: {
        basketSelectedProducts: {
            deep: true,
            handler (value) {
                // <products-variations>
                // Remove a variation if its product is no longer selected.
                for (const productId in this.basket.selectedProductsVariations) {
                    if (!value.find((product) => Number.parseInt(product.id) === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsVariations[productId];
                    }
                    else {
                        const variationsCopy = cloneDeep(this.basket.selectedProductsVariations);
                        const productVariations = [];

                        for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(productId); ++i) {
                            productVariations[i] = variationsCopy[productId][i] ?? [];
                        }

                        variationsCopy[productId] = productVariations;
                        this.basket.selectedProductsVariations = variationsCopy;
                    }
                }
                // </products-variations>

                // <minimum-total-fee>
                // const difference = 12 - (this.basketTotal - this.basketTotalAddition);

                // if (difference > 0 && value.length > 0) {
                //     this.basket.additions = [ { description: "Aggiunta per minimo spesa 12 euro", value: difference, }];
                // }
                // else if (difference <= 0 || value.length === 0) {
                //     this.basket.additions = [];
                // }
                // </>minimum-total-fee>
            },
        },
    },
    created () {
        window.addEventListener("beforeunload", () => {
            window.localStorage.setItem(`FortuneRMS::${this.restaurantId}::LastBasket`, JSON.stringify(this.basket));
        });
    },
    mounted () {
        this.showPickUpModalBox();
        if (!this.hasLastValidAddress) {
            // this.showCustomerDistanceModalBox();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.cashier-sale-point-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

.header {
    position: relative;

    height: 14%;

    background-color: rgb(42, 40, 42);

    :deep(.primary-logo) {
        position: absolute;
        right: 60px;
        top: 0;

        width: 92px;

        fill: rgb(0, 0, 0);

        transform: scale(8);
        opacity: 0.2;
    }
}

.main {
    height: 80%;

    background-color: rgb(42, 40, 42);

    :deep(.restaurant-product-list-vertical) {
        @extend .flex--y-align-center;

        background-color: rgb(245, 245, 245);

        overflow: hidden;

        margin: 0;
        width: 68%;
        height: 84%;

        &::before {
            height: 34%;
        }

        .slider-container-item {
            flex: 1 1 calc(50% - 30px);  /* Each item is 20% width minus the gap */
            max-width: calc(50% - 30px); /* Ensure the max-width is the same as flex-basis */

            :deep(.preview-horizontal__image) {
                width: 110px!important;
                height: 110px!important;
            }

            :deep(.restaurant-product) {
                max-height: none!important;
            }
        }

        .slider-container::after {
            content: "";
            flex: 1 1 calc(50% - 30px); /* Same width as flex items */
            max-width: calc(50% - 30px);
            visibility: hidden; /* Invisible filler */
        }

        .slider-container {
            width: 100%;
        }
        // .categories {
        //     height: 680px!important;
        // }

        .slider {
            margin: 0;
            padding: 0;

            z-index: 2;
        }

    }
    :deep(.restaurant-product-list .slider) {
        min-height: 80%;
    }

    :deep(> .customer-basket) {
        overflow: hidden;

        width: 32%;
        height: 76%;
        margin: 0;
        padding: 25px 0 0 0;

        background-color: rgb(255, 255, 255);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px 12px 0 0;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.03);

        z-index: 3;
    }
    :deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }
    :deep(> .customer-basket .basket) {
        height: 100%;
    }
    :deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 80%;
        padding: 0 25px 25px 25px;
    }
    :deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 20%;
        margin: 0;
        padding: 0;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    :deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }
    :deep(> .customer-basket .discount) {
        display: none;
    }
    :deep(> .customer-basket .total) {
        display: none;
    }
}

.footer {
    height: 6%;

    background-color: rgb(42, 40, 42);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.legal-link {
    cursor: pointer;

    margin: 0 20px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(230, 230, 230);
}

.action-box {
    position: relative;

    height: 16%;

    background-color: rgb(250, 250, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    z-index: 5;

    :deep(.simple-button + .simple-button) {
        margin-left: 16px;
    }
}

.order-confirmation-box {
    width: 55%;
    height: 100%;

    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .basket-box {
        height: 80%;
    }
    .basket-box:deep(> .customer-basket) {
        overflow: hidden;

        width: 100%;
        height: 100%;
        margin: 0;
        padding: 25px 0 0 0;

        background-color: transparent;
    }
    .basket-box:deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }
    .basket-box:deep(> .customer-basket .basket) {
        height: 100%;
    }
    .basket-box:deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 70%;
        padding: 0 25px 25px 25px;
    }
    .basket-box:deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 30%;
        margin: 0;
        padding: 0;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .basket-box:deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }
    .basket-box:deep(> .customer-basket .discount) {
        margin: 15px 0 0 0;
        padding: 0 40px;
    }
    .basket-box:deep(> .customer-basket .total) {
        margin: 15px 0 0 0;
        padding: 0 40px;
    }

    .action-box {
        height: 20%;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.order-payment-box {
    width: 45%;
    height: 100%;

    background-color: rgb(246, 246, 246);

    .title {
        height: inherit;

        &__text {
            @extend .--unselectable;

            font-weight: 600;
            font-size: 20px;
            color: rgb(160, 160, 160);
        }
    }
}

.cash-payment-box {
    height: inherit;

    :deep(.cashier-payment-calculator) {
        margin: 50px 0;
    }
}

.card-payment-box {
    height: inherit;
}

.third-party-payment-box {
    height: inherit;
}

.full-height {
    height: 100%;
}

.third-party-select-box {
    margin-top: 50px;
    width: 80%;
}

.order-confirm-notice-text {
    width: 100%;
    margin: 20px;

    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: rgb(232, 142, 27);
}

.payment-method-button {
    &--selected:deep(.simple-button__text) {
        color: $primary-brand-color;
    }
}

.delivery-location-button {
    &--selected:deep(.simple-button__text) {
        color: $primary-brand-color;
    }
}

.order-completed-box {
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);

    z-index: 50;

    &__daily-id-text {
        position: relative;

        margin-top: 20px;

        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);

        &::after {
            content: "";

            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;

            background-color: $primary-brand-color;
        }
    }

    &__customer-rest-text {
        margin-top: 20px;
        padding: 6px 25px;

        background-color: $primary-brand-color;
        border-radius: 1000px;

        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }

    .order-completed-icon-box {
        margin: 0;
        padding: 20px;

        border: 4px solid rgb(20, 153, 98);
        border-radius: 50%;

        &__icon {
            width: 48px;
            height: 48px;

            fill: rgb(20, 153, 98);
        }
    }
}

.restaurant-product-list-vertical {
    :deep(.restaurant-product) {
        max-height: 208px;
    }
    :deep(.restaurant-product .preview-horizontal) {
        &__image {
            width: 112px;
            height: 112px;
        }
    }
    :deep(.restaurant-fixed-menu .preview) {
        &__image {
            width: 100%;
            max-width: 112px;
        }
        &__image:nth-child(2) {
            width: 100%;
            max-width: 148px;
        }
    }
}

.language-selector {
    position: absolute;
    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 12px;

    z-index: 10;

    :deep(.language) {
        background-color: transparent;
        border-radius: 0;
    }
    :deep(.language.language--selected) {
        background-color: transparent;
    }
    :deep(.language .language__name) {
        display: none;
    }
    :deep(.language .language__icon) {
        margin: 0;

        opacity: 0.5;
    }
    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }
    :deep(.language + .language) {
        margin: 0;
    }
}

.restaurant-name {
    font-size: 19px;
    font-weight: 800;
    color: rgb(255, 255, 255);
}

.customer-address-box {
    padding: 30px 50px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    $max-input-width: 586px;
    $two-input-space: 16px;

    .address-textbox {
        width: 90%;
        max-width: 586px;
    }
    .address-number-textbox {
        width: calc(45% - #{$two-input-space / 2});
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2});

        margin: 16px $two-input-space 0 0;
    }
    .cap-textbox {
        width: calc(45% - #{$two-input-space / 2});
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2});

        margin: 16px 0 0 0;
    }
}

.customer-id-box {
    padding: 30px 50px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .full-name-textbox {
        width: 90%;
        max-width: 586px;
    }

    .mobile-textbox {
        width: 90%;
        max-width: 586px;

        margin: 16px 0 0 0;
    }
}

.delivery-timestamp-box {
    padding: 30px 50px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__question-text {
        font-size: 16px;
        font-weight: 600;
        color: rgb(11, 10, 11);
    }

    :deep(.simple-selectbox) {
        width: 90%;
        max-width: 586px;

        margin: 16px 0 0 0;
        padding: 0;
    }
    :deep(.simple-selectbox .choices) {
        color: rgb(35, 32, 33);
    }
    :deep(.simple-selectbox .choices::after) {
        border-color: rgb(33, 33, 33) transparent transparent;
    }
    :deep(.simple-selectbox .choices.is-open::after) {
        border-color: transparent transparent rgb(33, 33, 33);
    }
    :deep(.simple-selectbox .choices .choices__inner) {
        min-height: initial;
        max-height: 46px;
        padding: 10px;

        background-color: rgb(253, 253, 253);
        border: 1px solid rgb(220, 220, 220);
        border-radius: 12px;

        vertical-align: initial;
    }
    :deep(.simple-selectbox .choices .choices__inner *) {
        font-weight: 400;
        color: rgb(35, 32, 33);
    }
    :deep(.simple-selectbox .choices .choices__list.choices__list--dropdown) {
        width: 100%;
        margin-top: 10px;

        background-color: rgb(255, 255, 255);
        border: none;
        border-radius: 6px;
    }
    :deep(.simple-selectbox .choices .choices__list:not(.choices__list--single)) {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);
    }
    :deep(.simple-selectbox .choices .choices__list.choices__list--dropdown *) {
        font-size: 16px;
        font-weight: 500;
        color: rgb(50, 50, 50);
    }
    :deep(.simple-selectbox .choices .choices__list.choices__list--dropdown .choices__item--selectable) {
        padding: 14px;
    }
}

.checkout-modal-box {
    :deep(.modal-box__slot) {
        min-height: 85vh;
        min-height: calc(var(--vh, 1vh) * 85);
        height: 85vh;
        height: calc(var(--vh, 1vh) * 85);

        margin: 10vh 0 5vh 0;
        margin: calc(var(--vh, 1vh) * 10) 0 calc(var(--vh, 1vh) * 5) 0;
    }

    :deep(.customer-order-checkout) {
        height: inherit;
    }
}

.action-buttons {
    width: 68%;
    justify-content: end;
}
.promo-code {
    width: 32%;

    .simple-textbox {
        margin-right: 20px;
    }
}
</style>
