<template>
    <div class="flex flex--100 mobile-home-view">
        <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
            <primary-logo v-show="restaurantId != '6'"/>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="restaurant-name">{{ restaurant.name }}, {{ restaurant.address }}</h2>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center main">
            <language-selector :available-languages="displayLanguages" @change="toOrderView"/>
        </div>
    </div>
</template>

<script>
import LanguageSelector from "@/components/inputs/LanguageSelector";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { server, } from "@/server";
import { MobileViewportSimulator, } from "@/utilities";

const preloadedData = {};
let hasPreloadedData = false;
let insideRestaurant = false;
let tableNumber = null;

export default {
    async beforeRouteEnter (to, from, next) {
        if (!hasPreloadedData) {
            preloadedData.restaurant = await server.getRestaurant(to.params.restaurantId);
            if (!preloadedData.restaurant.isOpen) {
                next(`/restaurant/${to.params.restaurantId}/closed`);

                return;
            }
            hasPreloadedData = true;
        }

        if (to.query.insideRestaurant === "true") {
            insideRestaurant = true;
        }

        if (to.query.tableNumber) {
            tableNumber = to.query.tableNumber;
        }

        next();
    },

    name: "MobileHomeView",
    components: {
        LanguageSelector,
        PrimaryLogo,
    },
    computed: {
        restaurant () {
            return preloadedData.restaurant;
        },

        restaurantId () {
            return this.restaurant.id;
        },

        displayLanguages () {
            if (!this.restaurant.configuration || !this.restaurant.configuration.userDisplayLocalizations) {
                return [];
            }
            return typeof this.restaurant.configuration.userDisplayLocalizations === "string"
                ? JSON.parse(this.restaurant.configuration.userDisplayLocalizations)
                : this.restaurant.configuration.userDisplayLocalizations;
        },

        orderViewLocation () {
            return `/restaurant/${this.restaurantId}/mobile/order`;
        },
    },
    methods: {
        toOrderView () {
            const query = {
                fromHome: "true",
                insideRestaurant: insideRestaurant ? "true" : "false",
                language: this.$i18n.locale,
            };
            if (tableNumber) {
                query.tableNumber = tableNumber;
            }
            this.$router.push({
                path: this.orderViewLocation,
                query,
            });
        },
    },
    mounted () {
        MobileViewportSimulator.activate();
        MobileViewportSimulator.update();
        const languageCount = this.displayLanguages.length;
        document.documentElement.style.setProperty('--languages-count', languageCount);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";
:root {
    --languages-count: 3;
}

.mobile-home-view {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.header {
    overflow: hidden;

    height: 30%;

    background-color: $primary-brand-color;
}

.main {
    overflow: hidden;
    cursor: pointer;

    height: 70%;

    background-color: rgb(255, 255, 255);

    &:active > .order-button {
        transform: scale(0.9);
    }
}

.primary-logo {
    width: 84px;
    height: auto;

    fill: rgb(255, 255, 255);
}

.language-selector {
    width: 100%;
    height: 100%;

    :deep(&__box) {
        height: 100%;

        background-color: $primary-brand-color;
    }

    :deep(.language) {
        @extend .flex--x-align-center;

        width: 100%;
        height: calc(100% / var(--languages-count));

        background-color: rgb(246, 246, 246);
        border-radius: 0;

        &__name {
            justify-content: center; //@extend is not working here with :deep

            width: 100%;

            font-size: 20px;
            font-weight: 800;
            text-transform: uppercase;
            color: rgb(11, 11, 11);
        }

        &__icon {
            margin: 12px 0 0 0;

            > svg {
                width: 28px;
            }
        }

        &--selected {
            background-color: rgb(246, 246, 246);
        }
    }
    :deep(.language + .language) {
        margin: 0;
    }

    :deep(&__box .language:first-child) {
        border-radius: 0;
    }
}

.restaurant-name {
    margin: 22px 12px 0 12px;
    padding: 0;

    text-align: center;
    font-size: 16px;
    font-weight: 800;
    color: rgb(255, 255, 255);
}
</style>
