<template>
    <div class="flex flex--100 variations">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--y-align-center add-variation-button" @click="showAddVariationModalBox">
                    <span class="add-variation-button__text">{{$t(`management.restaurantVariationsTab.newVariation`).toLocaleUpperCase()}}</span>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex add-variation-button__icon">
                        <g stroke="none" stroke-width="1" fill-rule="evenodd">
                            <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                            <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                        </g>
                    </svg>
                </div>
                <div class="flex flex--100 action-box">
                    <div class="flex flex--20 search-bar">
                        <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                    </div>
                    <div class="flex flex--80 flex--x-align-end">
                        <simple-button class="action-button" :class="showActiveButtonModifiers" :text="$t(`management.restaurantVariationsTab.active`).toLocaleUpperCase()" @click="showUnarchived()" v-show="!exportMode"/>
                        <simple-button class="action-button" :class="showArchivedButtonModifiers" :text="$t(`management.restaurantVariationsTab.archived`).toLocaleUpperCase()" @click="showArchived()" v-show="!exportMode"/>
                        <simple-button class="action-button" :text="$t(`management.generic.import`).toLocaleUpperCase()" @click="openNativeFilePicker" v-show="!exportMode" :is-loading="isWaitingFileLoad"/>
                        <input @change="onFileChange" ref="fileInput" type="file" accept="application/JSON" style="display:none">
                        <simple-button class="action-button" :class="selectAllButtonModifiers" :text="$t(`management.generic.selectAll`).toLocaleUpperCase()" @click="toggleSelectAllToExport" v-show="exportMode"/>
                        <simple-button class="action-button" :text="$t(`management.generic.export`).toLocaleUpperCase()" @click="toggleExportMode(true)"/>
                        <simple-button class="action-button" :text="$t(`management.generic.cancel`).toLocaleUpperCase()" @click="toggleExportMode(false)" v-show="exportMode"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center variation-list">
                <span class="no-variations-text" v-if="variations.length === 0 && archivedVariations === 0">{{$t(`management.restaurantVariationsTab.noVariations`)}}</span>
                <div class="restaurant-variation-box" v-for="variation in filteredVariations" :key="variation.id">
                    <restaurant-variation
                        :variation="variation"
                        :restaurant-id="restaurantId"
                        :restaurant-presentation="restaurantPresentation"
                        :selectable="true"
                        :show-editor="true"
                        @variation-update="onVariationUpdate"
                    >
                    </restaurant-variation>
                    <div class="toggle-button" @click="toggleVariationStatus(variation)">
                        <svg v-if="!exportMode && !variation.archived" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        <svg v-if="!exportMode && variation.archived" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10 9l-6 6 6 6"/><path d="M20 4v7a4 4 0 0 1-4 4H5"/></svg>
                        <svg v-if="exportMode && !variation.export" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        <svg v-if="exportMode && variation.export" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    </div>
                </div>
            </div>
        </div>
        <add-variation-modal-box :restaurant-presentation="restaurantPresentation" :restaurant-id="restaurantId" ref="addVariationModalBox" @variation-add="onVariationAdd"/>
    </div>
</template>

<script>
import {server} from "@/server";
import AddVariationModalBox from "@/components/windows/AddVariationModalBox";
import RestaurantVariation from "@/components/RestaurantVariation";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import Fuse from "fuse.js";
import SimpleButton from "@/components/inputs/SimpleButton";
import {RestaurantProcessor} from "@/utilities";
import {user} from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";

export default {
    name: "RestaurantVariationsTab",
    components: {
        RestaurantVariation,
        AddVariationModalBox,
        SimpleTextbox,
        SimpleButton,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantPresentation: {},
            variations: [],
            archivedVariations: [],
            restaurantId: undefined,
            selectedVariation: undefined,
            searchBarValue: "",
            showArchivedFlag: false,
            isWaitingServerResponse: false,
            exportMode: false,
            selectedExportVariations: [],
            isWaitingFileLoad: false,
            selectAllOn: false,
        };
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // <fuse>
        fuse () {
            const fuseItems = this.showArchivedFlag ? this.archivedVariations : this.variations;
            return new Fuse(fuseItems, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>
        filteredVariations () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return this.showArchivedFlag ? this.archivedVariations : this.variations;
        },

        selectAllButtonModifiers () {
            return {
                "action-button__selected": this.selectAllOn,
            }
        },

        showArchivedButtonModifiers () {
            return {
                "action-button__selected": this.showArchivedFlag,
            }
        },

        showActiveButtonModifiers () {
            return {
                "action-button__selected": !this.showArchivedFlag,
            }
        },
    },
    methods: {
        async load (restaurantId) {
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.variations = this.restaurantPresentation.variations;
            this.restaurantId = restaurantId;

            // Clear
            this.clear();
        },

        clear () {
            this.selectAllOn = false;
            this.selectedExportVariations = [];
            this.isWaitingFileLoad = false;
            this.isWaitingServerResponse = false;
            this.exportMode = false;
            this.searchBarValue = "";
            this.showArchivedFlag = false;
        },

        onVariationAdd (variation) {
            this.variations.push(variation);
        },

        showAddVariationModalBox () {
            this.$refs.addVariationModalBox.window.show();
        },

        onVariationUpdate (variation) {
            const index = this.findVariationIndexById(this.variations, variation.id);

            if (index !== -1) {
                this.variations.splice(index, 1);
            }
            this.variations.push(variation);
        },

        findVariationIndexById (variations, id) {
            for (let i = 0; i < variations.length; ++i) {
                const variation = variations[i];

                if (variation.id === id) {
                    return i;
                }
            }

            return -1;
        },

        async getArchivedVariations () {
            try {
                this.archivedVariations = await server.getRestaurantArchivedVariations(this.restaurantId);
            }
            catch (error) {
                console.log(error);
            }
        },

        async getActiveVariations () {
            try {
                this.variations = await server.getRestaurantVariations({
                    restaurantId: this.restaurantId
                });
            }
            catch (error) {
                console.log(error);
            }
        },

        async showUnarchived () {
            if (!this.showArchivedFlag) {
                return;
            }
            this.showArchivedFlag = false;
            await this.getActiveVariations();
        },

        async showArchived () {
            if (this.showArchivedFlag) {
                return;
            }
            this.showArchivedFlag = true;
            await this.getArchivedVariations();
        },

        async toggleVariationStatus (variation) {
            if (!this.exportMode) {
                await this.toggleArchive(variation);
            }
            else {
                const index = this.findVariationIndexById(this.selectedExportVariations, variation.id);
                if (index !== -1) {
                    variation.export = false;
                    this.selectedExportVariations.splice(index, 1);
                }
                else {
                    variation.export = true;
                    this.selectedExportVariations.push(variation);
                }
                this.variations.sort(); // Force rerender
            }
        },

        async toggleArchive (variation) {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;


            if (!variation.archived) {
                const index = this.findVariationIndexById(this.variations, variation.id);
                if (index !== -1) {
                    this.variations.splice(index, 1);
                    await this.archive(variation);
                }
            }
            else {
                const index = this.findVariationIndexById(this.archivedVariations, variation.id);
                if (index !== -1) {
                    this.archivedVariations.splice(index, 1);
                    await this.unarchive(variation);
                }
            }

            this.isWaitingServerResponse = false;
        },

        async archive (variation) {
            try {
                const responseVariation = await user.archiveVariation({
                    id: variation.id,
                });

                if (responseVariation) {
                    variation.archived = true;
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.variationArchivedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
        },

        async unarchive (variation) {
            try {
                const responseVariation = await user.unarchiveVariation({
                    id: variation.id,
                });

                if (responseVariation) {
                    variation.archived = false;
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.variationUnarchivedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
        },

        toggleExportMode (value) {
            this.showArchivedFlag = false; // Force unarchived variations display

            if (value && !this.exportMode) {
                this.exportMode = value; // First click to choose variation to export
            }
            else if (value && this.exportMode) { // Second click, confirm export
                const exportData = this.selectedExportVariations.map((variation) => {
                    return {
                        localizations: variation.localizations.map((localization) => {
                            return {
                                name: localization.name,
                                languageIso: localization.languageIso,
                            };
                        }),
                        price: variation.price,
                    }
                });
                if (exportData.length > 0) {
                    // <Create JSON file>
                    const a = document.createElement("a");
                    const file = new Blob([JSON.stringify(exportData)], { type: "text/plain" });
                    a.href = URL.createObjectURL(file);
                    a.download = `variations_${Date.now()}.json`;
                    a.click();
                    // </Create JSON file>
                    this.selectedExportVariations.forEach((v) => v.export = false);
                    this.selectedExportVariations = [];
                    this.exportMode = false;
                    this.variations.sort();
                }
            }
            else {
                this.exportMode = value; // Cancel export
            }
        },

        openNativeFilePicker () {
            this.$refs.fileInput.click();
        },

        async onFileChange (event) {
            if (this.isWaitingFileLoad) {
                return;
            }

            this.isWaitingFileLoad = true;

            try {
                const file = event.target.files[0];
                const fileReader = new FileReader();

                fileReader.readAsText(file);
                fileReader.addEventListener("error", () => {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),})
                });
                fileReader.addEventListener("load", async (event) => {
                    const data = event.target.result;

                    const response = await user.bulkCreateVariations({
                        variations: JSON.parse(data),
                        restaurantId: this.restaurantId,
                    });

                    if (response.status === "ok") {
                        this.variations = await server.getRestaurantVariations({
                            restaurantId: this.restaurantId
                        });
                    }
                    this.isWaitingFileLoad = false;
                });
            }
            catch (error) {
                console.log(error);
                this.isWaitingFileLoad = false;
            }
        },

        toggleSelectAllToExport () {
            if (this.selectedExportVariations.length === this.variations.length) {
                this.selectAllOn = false;
                this.variations.forEach((v) => {
                    v.export = false;
                })
                this.selectedExportVariations = [];
            }
            else {
                this.selectAllOn = true;
                this.variations.forEach((v) => {
                    v.export = true;
                    this.selectedExportVariations = [ ...this.variations, ];
                })
            }
            this.variations.sort();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-variation-button {
    cursor: pointer;

    margin: 0 0 50px 25px;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 13px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }
}

.modify-variation-button {
    cursor: pointer;

    margin: 0 0 50px 25px;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 14px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }
}

.no-variations-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.variation-list {
    margin: 0;

    background-color: rgb(255, 255, 255);
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.restaurant-variation-box {
    position: relative;

    width: 100%;

    cursor: pointer;

    :deep(.restaurant-variation) {
        background-color: rgb(240, 240, 240);
        border-radius: 0;

        &__name {
            padding: 3px;
            font-size: 16px;
            font-weight: 500;
            color: rgb(0, 0, 0);
        }

        &:hover {
            background-color: rgb(226, 226, 226);
        }
    }

    .toggle-button {
        position: absolute;
        right: 0;
        top: 25%;
        bottom: 25%;
        margin-top: auto;
        margin-bottom: auto;
        min-width: 40px;

        &:hover {
            .toggle-button__icon {
                transform: scale(1.3);
            }
        }
    }
}

.search-bar {
    position: relative;

    margin: 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }
}

.action-box {
    margin-bottom: 50px;

    .action-button {
        border-radius: 5px;
        padding: 10px;
        min-width: 100px;
        margin-left: 15px;

        &__selected {
            :deep(.simple-button__text) {
                color: $primary-brand-color;
            }
        }
    }
}

</style>
