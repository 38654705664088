<template>
    <div class="flex flex--100 kiosk-home-view">
        <language-selector  :available-languages="displayLanguages" @change="toOrderView"/>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
            <primary-logo/>
        </div>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center main" @click="toOrderView">
            <simple-button :text="$t('kiosk.touchToOrder').toUpperCase()" class="order-button"/>
            <product-background-animation/>
        </div>
    </div>
</template>

<script>
import LanguageSelector from "@/components/inputs/LanguageSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import ProductBackgroundAnimation from "@/components/utilities/ProductBackgroundAnimation";
import { server } from "@/server";
import {i18n, preloadImage} from "@/main";
import { RestaurantProcessor } from "@/utilities";

const preloadedData = {};
let hasPreloadedData = false;
let scene;

async function preloadData (restaurantId) {
    const [ restaurantPresentation, ] = await Promise.all([
        server.getRestaurantPresentation(restaurantId),
    ]);

    preloadedData.restaurantPresentation = restaurantPresentation;
}

function preloadProductsPreviewImages () {
    for (const product of preloadedData.restaurantPresentation.products) {
        if (product.previewImage) {
            preloadImage(product.previewImage.uri);
        }
    }
}

export default {
    async beforeRouteEnter (to, from, next) {
        i18n.locale = "it";
        scene = !to.query.sceneId || to.query.sceneId === "undefined" || to.query.sceneId === "all" ? undefined : to.query.sceneId;

        if (hasPreloadedData) {
            next();

            return;
        }

        await preloadData(to.params.restaurantId);
        if (!preloadedData.restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }
        preloadProductsPreviewImages();

        hasPreloadedData = true;

        next();
    },

    name: "KioskHomeView",
    components: {
        LanguageSelector,
        PrimaryLogo,
        SimpleButton,
        ProductBackgroundAnimation,
    },
    data () {
        return {
            restaurantPresentation: preloadedData.restaurantPresentation,
            scene,
        };
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        displayLanguages () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },

        restaurantId () {
            return this.$route.params.restaurantId;
        },

        orderViewLocation () {
            return `/restaurant/${this.restaurantId}/kiosk/order${scene ? `?sceneId=${scene}` : ''}`;
        },
    },
    methods: {
        toOrderView () {
            this.$router.push(this.orderViewLocation);
        },
    },
    created () {
        const customStyleElement = this.restaurantProcessor.getCustomStyleElement();
        
        window.document.head.appendChild(customStyleElement);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.kiosk-home-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

.header {
    overflow: hidden;

    height: 45%;

    background-color: $primary-brand-color;
}

.main {
    overflow: hidden;
    cursor: pointer;

    height: 55%;

    background-color: rgb(255, 255, 255);

    &:active > .order-button {
        transform: scale(0.9);
    }
}

.primary-logo {
    width: 262px;
    height: auto;

    fill: rgb(255, 255, 255);
}

// .language-selector {
//     position: absolute;
//     left: 0;
//     top: 50%;

//     width: 100vw;

//     z-index: 1000;

//     :deep(&__box) {
//         @extend .flex--x-align-center;
//     }

//     :deep(.language) {
//         height: 48px;
//     }
// }

:deep(.language-selector) {
  position: absolute;
  left: 50%;              // Move container's left edge to the center of the viewport
  top: 50%;               // Move container's top edge to the center of the viewport
  transform: translate(-50%, -50%); // Center the container by shifting it left/up by 50%
  width: 500px;           // Fixed width for the container (adjust as needed)
  z-index: 1000;

  &__box {
    display: grid;
    grid-template-columns: repeat(3, auto); // Create 3 columns sized to content
    grid-gap: 12px;                         // Gap between grid items
    justify-content: center;                // Center the grid items within the container if they don't fill it
  }

  .language {
    height: 48px;
    width: auto; // Let each item use its content width

    &__icon {
      width: 24px;
      border-radius: 4px;
    }
  }

  .language + .language {
    margin-left: 0; // Remove any extra left margin if added by other rules
  }
}


.order-button {
    will-change: transform;
    transition: transform 300ms;
    background-color: transparent;

    :deep(.simple-button__text) {
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0.05rem;
        color: $primary-brand-color;
    }
}

.product-background-animation {
    :deep(&__image:nth-child(3)) {
        top: -230px;
    }
    :deep(&__image:nth-child(10)) {
        top: -10px;
    }
}
</style>
